import { forwardRef, useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import PriceCard from "component/PriceCard";
import { crossIcon } from "assets";
import AuthContext from "contexts/authContext";
import { createCheckoutSession, fetchSubscription } from "services/Auth";
import _ from "lodash";
import Loader from "component/Loader";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PriceSelectionDialog = (props) => {
  const { children, className = "" } = props;
  const { auth } = useContext(AuthContext);
  const [loader, setLoader] = useState(true);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const fetchAndCheckSubscription = async () => {
    setLoader(true);
    if (
      _.get(auth, "userData.Subscription[0].status", "") === "active" ||
      _.get(auth, "userData.Subscription[0].status", "") === "trialing"
    ) {
      let subDetails = await fetchSubscription();
      const portalUrl = _.get(subDetails, "data.portalSessionUrl", "");
      if (portalUrl) {
        window.location.href = portalUrl;
      } else setLoader(false);
    } else setLoader(false);
  };

  const gotoCheckout = async (type, skipTrial) => {
    setLoader(true);
    const response = await createCheckoutSession(type, skipTrial, false);
    const url = _.get(response, "data.url", "");
    if (url) {
      window.location.href = url;
    }
    setLoader(false);
  };
  useEffect(() => {
    if (open) fetchAndCheckSubscription();
  }, [auth, open]);

  return (
    <>
      <span onClick={handleClickOpen} className={className}>
        {children}
      </span>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            maxWidth: "1080px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {loader && (
              <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-sm content-center">
                <Loader />
              </div>
            )}
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="mb-12 mt-4 rounded-md max-w-4xl mx-auto">
              <div className="text-center font-semibold text-2xl mb-7">
                This Feature is Available only for Professional Plan!
              </div>
              <div className="flex gap-5 flex-col md:flex-row">
                <PriceCard
                  planName="Trial"
                  lines={[
                    "Cut Reels with help of AI",
                    "Unlimited Amount of Reel cuts",
                    "Unlimited Gaming Backgrounds",
                  ]}
                  price="$1.00"
                  priceLine="*3 days trial, auto-renewed monthly"
                  onClick={() => gotoCheckout("monthly", false)}
                ></PriceCard>
                <PriceCard
                  planName="Monthly"
                  lines={[
                    "Cut Reels with help of AI",
                    "Unlimited Amount of Reel cuts",
                    "Unlimited Gaming Backgrounds",
                  ]}
                  price="$39.00"
                  priceLine="*per month, charged monthly"
                  onClick={() => gotoCheckout("monthly", true)}
                ></PriceCard>
                <PriceCard
                  planName="Annually"
                  lines={[
                    "Cut Reels with help of AI",
                    "Unlimited Amount of Reel cuts",
                    "Generate Stories with help of AI",
                    "Unlimited Gaming Backgrounds",
                  ]}
                  price="$349.00"
                  onClick={() => gotoCheckout("annual", true)}
                  priceLine="**per month, charged annually"
                ></PriceCard>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PriceSelectionDialog;
