import {
  greenCheckIcon,
  instaIcon,
  purpleBottomInsideShadow,
  purpleCenterInsideShadow,
  reelPreviewCard,
  tikTokCreativeProgram,
  tiktokCard,
  tiktokIcon,
  xIcon,
} from "assets";
import Accordion from "component/Accordion";
import PriceCard from "component/PriceCard";
import WorkTabs from "component/WorkTab";
import LoginDialog from "dialogs/Login";
import React from "react";

function Landing() {
  return (
    <>
      <nav className="bg-white px-4 py-3 border border-[#E2E2E2] shadow-sm">
        <div className="max-w-screen-lg mx-auto flex justify-between items-center gap-5">
          <div className="text-black font-semibold text-2xl items-center cursor-pointer">
            Captora
          </div>
          <ul className="gap-x-4 font-medium text-sm max-w-80 w-full justify-evenly hidden md:flex">
            <li
              className="cursor-pointer"
              onClick={() =>
                document
                  .getElementById("about")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              About
            </li>
            <li
              className="cursor-pointer"
              onClick={() =>
                document
                  .getElementById("pricing")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Pricing
            </li>
            <li
              className="cursor-pointer"
              onClick={() =>
                document
                  .getElementById("faq")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              FAQs
            </li>
          </ul>
          <LoginDialog>
            <button className="btn bg-white text-black border-purple font-semibold text-sm py-2 px-8 rounded-lg w-full">
              Log in
            </button>
          </LoginDialog>
        </div>
      </nav>
      <section className="relative px-4 py-20 overflow-x-hidden">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex justify-center">
            <img
              className="h-72 w-80 mb-2 hidden md:block"
              src={tiktokCard}
              alt="tiktokCard"
            />
            <div className="mt-7 text-center">
              <h2 className="text-black text-5xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-[640] text-center max-w-[721px]">
                Create <span className="text-[#7F43FF]">Viral</span> Faceless
                Videos Effortlessly!
              </h2>
              <p className="pt-5 text-center max-w-[552px] font-light text-lg text-md lg:text-md xl:text-lg 2xl:text-xl mx-auto">
                Produce viral videos in short reel format without needing to
                appear on camera or invest in costly gear.
              </p>
              <LoginDialog>
                <button className="mt-7 btn bg-purple text-white font-medium py-4 px-12 rounded-lg w-full sm:w-auto disabled:opacity-75">
                  Get Started
                </button>
              </LoginDialog>
            </div>
            <img
              className="h-72 w-80 mt-5 hidden md:block"
              src={reelPreviewCard}
              alt="reelPreviewCard"
            />
          </div>
          <div className="flex gap-2 md:gap-4 max-w-[768px] m-auto justify-between mt-12 mb-3 flex-col md:flex-row">
            <span className="flex gap-2 justify-center">
              <img src={greenCheckIcon} alt="greenCheckIcon" className="w-5" />
              <span>Unlimited Reel cuts</span>
            </span>
            <span className="flex gap-2 justify-center">
              <img src={greenCheckIcon} alt="greenCheckIcon" className="w-5" />
              <span>Cut video emphasis with help of AI</span>
            </span>
            <span className="flex gap-2 justify-center">
              <img src={greenCheckIcon} alt="greenCheckIcon" className="w-5" />
              <span>3 Days Trial for $ 1</span>
            </span>
          </div>
        </div>
        <img
          src={purpleBottomInsideShadow}
          alt="purpleBottomInsideShadow"
          className="absolute bottom-0 -z-10 max-w-full"
        />
      </section>
      <section className="px-12 md:px-24 xl:px-48 py-20">
        <div className="max-w-screen-xl mx-auto" id="about">
          <div class="text-4xl font-extrabold text-[#242424] text-center">
            How it Works
          </div>
          {/* <div class="mt-[50px] text-center flex justify-center items-center gap-3">
            <button class="bg-black text-[#FFFFFF] py-[6px] px-4 rounded-full font-semibold text-sm">
              Reels from Video
            </button>
            <button class="bg-[#F4F4F4] text-[#242424] py-[6px] px-4 rounded-full font-semibold text-sm">
              Prompt Stories
            </button>
          </div> */}
        </div>
        <WorkTabs />
      </section>
      <section className="px-4 py-20 bg-[#7F43FF0F]">
        <div className="max-w-screen-xl mx-auto">
          <div className="border-none py-5 lg:py-10 xl:py-20 px-4 rounded-2xl bg-white ">
            <div className="max-w-[1080px] flex gap-10 mx-auto flex-col-reverse lg:flex-row">
              <div className="flex-1 content-center max-w-[524px] mx-auto">
                <h4 className="text-black  text-4xl font-semibold ">
                  Fast forward your journey to TikTok creator programme!
                </h4>
                <p className="text-lg font-light text-justify mt-5 max-w-[484px]">
                  The TikTok Creativity Programme enables all creators to be
                  able to earn a substantial income, given the right views and
                  follower count. You will be able to mass produce content at a
                  fraction of the time with Captora AI, speeding up the process
                  of qualifying as a TikTok creator that can monetize videos!
                </p>
              </div>

              <img
                className="w-full flex-1 max-w-[524px] mx-auto"
                src={tikTokCreativeProgram}
                alt="tikTokCreativeProgram"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 py-20" id="pricing">
        <div className="flex-1 p-4 rounded-2xl flex flex-col md:min-h-[500px] relative">
          <div className="font-semibold text-4xl text-center mb-8">Pricing</div>
          <div className="flex gap-4 flex-col md:flex-row max-w-screen-lg w-full mx-auto">
            <LoginDialog className="flex-1">
              <PriceCard
                planName="Trial"
                lines={[
                  "Cut Reels with help of AI",
                  "Unlimited Amount of Reel cuts",
                  "Unlimited Gaming Backgrounds",
                ]}
                price="$1.00"
                priceLine="*3 days trial, auto-renewed monthly"
                onClick={() => {}}
                hasBorder={false}
              ></PriceCard>
            </LoginDialog>
            <LoginDialog className="flex-1">
              <PriceCard
                planName="Monthly"
                lines={[
                  "Cut Reels with help of AI",
                  "Unlimited Amount of Reel cuts",
                  "Unlimited Gaming Backgrounds",
                ]}
                price="$39.00"
                priceLine="*per month, charged monthly"
                onClick={() => {}}
                hasBorder={false}
              ></PriceCard>
            </LoginDialog>
            <LoginDialog className="flex-1">
              <PriceCard
                planName="Annually"
                lines={[
                  "Cut Reels with help of AI",
                  "Unlimited Amount of Reel cuts",
                  "Unlimited Gaming Backgrounds",
                ]}
                price="$349.00"
                priceLine="**per month, charged annually"
                hasBorder={false}
              ></PriceCard>
            </LoginDialog>
          </div>
          <img
            src={purpleCenterInsideShadow}
            alt="purpleCenterInsideShadow"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
          />
        </div>
      </section>
      <section className="px-4 py-20" id="faq">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-4xl font-semibold mb-6">FAQ</div>
          <Accordion
            q="What is Captora AI?"
            a="Captora is your ticket to crafting captivating viral videos without the need for a camera or expensive equipment. It's a platform where you can unleash your creativity and produce short, engaging reels that have the potential to go viral and help you earn an income with the TikTok creativity program. With Captora AI, you're empowered to express yourself and share your stories with the world effortlessly and cost-effectively."
          />
          <Accordion
            q="Why Captora AI?"
            a="Have you seen those viral videos with satisfying videos next to them? Or the gameplay video next to a podcast? That is what helps people create viral videos as they ensure the viewer’s attention is retained throughout the video. Captora does everything from start to finish, automatically! You do not need to give up anywhere near as much time as those who do things manually, and the results you get will be better than most!"
          />
          <Accordion
            q="What if this is not for me?"
            a="We are committed to helping everyone (who wants to) become a creator and earn an income. We are so sure you will love our product. That’s why we only take $1 upfront for you to get a real taste of the platform before committing."
            border={false}
          />
        </div>
      </section>
      <footer className="pt-4">
        <div className="border-solid border-[#e9e9e9] bg-white  border-t-[1px]  py-5">
          <div className="mt-5 max-w-screen-xl mx-auto w-full p-4">
            <div className="flex gap-4 md:gap-10 w-full mb-16 flex-col md:flex-row">
              <div className="text-[#373737] font-semibold text-xl cursor-pointer">
                Captora
              </div>
              <div className="flex flex-1 w-full gap-5 sm:items-center flex-col sm:flex-row">
                <div className="flex-1">
                  <ul className="flex flex-1 justify-between gap-x-4 text-sm max-w-[335px]">
                    <li
                      className="cursor-pointer"
                      onClick={() =>
                        document
                          .getElementById("about")
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      About
                    </li>
                    <li
                      className="cursor-pointer"
                      onClick={() =>
                        document
                          .getElementById("pricing")
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Pricing
                    </li>
                    <li
                      className="cursor-pointer"
                      onClick={() =>
                        document
                          .getElementById("faq")
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      FAQs
                    </li>
                    <li
                      className="cursor-pointer"
                      onClick={() => window.open("mailto:info@captora.ai")}
                    >
                      Contact us
                    </li>
                  </ul>
                </div>

                <div className="flex gap-3">
                  <img
                    src={instaIcon}
                    alt="instaIcon"
                    className="cursor-pointer"
                  />
                  <img src={xIcon} alt="xIcon" className="cursor-pointer" />
                  <img
                    src={tiktokIcon}
                    alt="tiktokIcon"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="border-solid border-[#e9e9e9]  border-t-[1px] py-5 flex justify-between gap-5 flex-col sm:flex-row">
              <div className="font-light text-sm cursor-pointer">
                © 2024 Captora.ai, All Rights Reserved
              </div>
              <div className="flex gap-5 text-sm cursor-pointer">
                <p>Cookies Policy</p>
                <p>Terms & Policies</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Landing;
