import React, { useRef } from "react";
import _ from "lodash";
import { greenCheckIcon } from "assets";

export const VideoPreview = ({
  videoSrc,
  onClick = () => {},
  gameId,
  backgroundId,
}) => {
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    if (videoRef.current && videoRef.current?.play) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current && videoRef.current?.pause) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video to start
    }
  };

  return (
    <div
      className="sm:h-64 h-auto rounded overflow-hidden cursor-pointer m-2"
      onClick={onClick}
    >
      <div
        className="relative sm:h-64 h-auto pb-full overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <video
          ref={videoRef}
          src={videoSrc}
          className="h-full max-w-[unset]"
          muted
          loop
          preload="auto"
          width="100%"
        />
        <div className="absolute sm:top-0 top-4 left-0 m-2">
          {gameId === backgroundId && (
            <img
              src={greenCheckIcon}
              alt="greenCheckIcon"
              key={gameId}
              width={20}
              height={20}
            />
          )}
        </div>
      </div>
    </div>
  );
};

function CreateStoryStep3({
  setStep,
  backgroundId,
  setBackgroundId,
}) {
  return (
    <div className="mt-4 rounded-md">
      <h3 className="text-2xl font-semibold mb-4">Choose Background</h3>
      <div className="md:flex grid sm:grid-cols-3 grid-cols-2 mt-4 overflow-auto sm:h-auto h-[calc(100%_-_250px)]">
        {_.map([4,1,6,3,5], (gameId) => (
          <VideoPreview
            videoSrc={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story-gameplay/gameplay_${gameId}.mp4`}
            key={"gameplay_" + gameId}
            onClick={() => setBackgroundId(gameId)}
            gameId={gameId}
            backgroundId={backgroundId}
          />
        ))}
      </div>
      <div className="mt-8 flex flex-col-reverse sm:flex-row gap-3 sm:gap-0">
        <div
          className="font-semibold text-md content-center cursor-pointer hidden sm:block"
          onClick={() => setStep(2)}
        >
          <span className="text-xl font-medium">{"<"}</span> Back
        </div>
        <div className="sm:ml-auto flex flex-col sm:flex-row gap-3 sm:gap-0">
          <button
            className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 disabled:opacity-75"
            onClick={() => setStep(4)}
            disabled={!backgroundId}
          >
            Add Voice
          </button>
          <button
            className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 blockssssss sm:hidden"
            onClick={() => setStep(2)}
          >
            Back to Step 2
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateStoryStep3;
