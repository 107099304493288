import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { CuteReelFromVideo, PersonalNoteBackground, slide1Img } from "assets";

const images = [CuteReelFromVideo, PersonalNoteBackground, slide1Img];

// Function to preload images
const preloadImages = (srcArray) => {
  srcArray.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

export default function WorkTabs() {
  const [activeTab, setActiveTab] = useState(0);
  const [fade, setFade] = useState(true);

  const tabs = [
    "Copy Video Link",
    "Cut Video Into Reel(s)",
    "Customise Reel(s)",
  ];

  // Function to change tab with fade animation
  const changeTab = (newTab) => {
    setFade(false);
    setTimeout(() => {
      setActiveTab(newTab);
      setFade(true);
    }, 500);
  };

  // Auto change steps every 5 seconds
  useEffect(() => {
    preloadImages(images);

    const interval = setInterval(() => {
      changeTab((prevTab) => (prevTab + 1) % tabs.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [tabs.length]);

  return (
    <Fragment>
      <div
        className={`transition-all duration-500 ${
          activeTab === 0
            ? "after:left-0"
            : activeTab === 1
            ? "after:left-1/2 after:-translate-x-1/2"
            : "after:right-0 after:left-[unset]"
        } before:w-full pt-5 pb-1 before:bg-[#daeed5] before:rounded-lg before:content-[''] before:absolute before:h-[10px] before:-bottom-[6px] before:left-0 rounded-lg max-w-[984px] m-auto relative after:content-[''] after:absolute after:-bottom-[6px] after:rounded-lg after:left-0 after:w-[35%] after:h-[10px] after:bg-[#92EE7B] `}
      >
        <div className="flex gap-2 md:gap-4  m-auto justify-evenly mt-1 sm:mt-12 mb-3 flex-col sm:flex-row md:flex-row text-[#454545] text-sm font-semibold">
          {tabs.map((tab, index) => {
            return (
              <div
                className="nav cursor-pointer"
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                <span className="hidden sm:inline-block">Step {index + 1}</span>
                <span className="hidden md:inline-block">: {tab}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`transition-opacity duration-500 ${
          fade ? "opacity-100" : "opacity-0"
        }`}
      >
        {activeTab === 0 ? (
          <div className="mt-[50px] lg:mt-[50px] flex flex-col-reverse lg:flex-row justify-center gap-[0px] lg:gap-[50px]">
            <div className="text-center max-w-[454px] mx-auto lg:mx-0">
              <button className="mt-2 btn bg-white font-bold py-4 rounded-lg w-full sm:w-auto disabled:opacity-75 relative">
                <img src={slide1Img} alt="slide1Img" />
              </button>
            </div>
            <div className="self-center">
              <h1 className="text-[#242424] font-semibold text-[30px] sm:text-[36px]">
                Copy Video as Link
              </h1>
              <p className="max-w-[324px] font-light text-md sm:text-lg">
                It’s that simple, just copy and paste any youtube link of your
                choice.
              </p>
            </div>
          </div>
        ) : activeTab === 1 ? (
          <div className="mx-auto max-w-[984px] m-auto mt-[60px] lg:mt-[20px]">
            <div className="sm:mt-[20px] flex flex-col lg:flex-row justify-center gap-[10px]">
              <div className="self-center">
                <h1 className="text-[#242424] font-semibold text-[30px] sm:text-[36px] max-w-[294px]">
                  Cut a Reel from Existing Video
                </h1>
                <p className="max-w-[294px] font-light text-md sm:text-lg">
                  Clip viral videos and add trending game play videos to trigger
                  the TikTok algorithm!
                </p>
              </div>
              <div className="text-center flex-1">
                <button className="mt-2 btn bg-white font-bold py-4 md:px-12 rounded-lg w-full sm:w-auto disabled:opacity-75 relative">
                  <img src={CuteReelFromVideo} alt="CuteReelFromVideo" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-screen-xl mx-auto">
            <div className="mt-[50px] sm:mt-[100px] flex flex-col lg:flex-row justify-center gap-[46px] lg:gap-[86px]">
              <div className="self-center">
                <img
                  src={PersonalNoteBackground}
                  alt="PersonalNoteBackground"
                />
              </div>
              <div className="self-center">
                <h1 className="text-[#242424] font-semibold text-[30px] sm:text-[36px] max-w-[337px]">
                  Add Trending Gameplay Videos
                </h1>
                <p className="max-w-[337px] font-light text-md sm:text-lg">
                  This method has proven to be successful for many creators.
                  Captora will automate it all for you!
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
