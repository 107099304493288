import { forwardRef, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { crossIcon } from "assets";
import VideoTrimmer from "component/VideoTrimmer";
import { VideoPreview } from "pages/ReelCutter/step2_5";
import _ from "lodash";
import { updateStoryBackground } from "services/Story";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditStoryDialog = (props) => {
  const { open, setOpen, story, callback } = props;
  const [backgroundId, setBackgroundId] = useState(story.backgroundId || 1);

  const [loading, setLoading] = useState(false);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    const response = await updateStoryBackground({
      backgroundId,
      jobId: story.jobId,
    });

    if (callback) callback({...response.data, backgroundId});
    setLoading(false);
    handleClose();
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            maxWidth: "1080px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="flex">
              <div className="max-w-[30%]">
                <div className="flex-col rounded overflow-auto">
                 
                  <VideoTrimmer
                    rangeValue={[0, 0]}
                    setRangeValue={(value) => {}}
                    streamUrl={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story-gameplay/gameplay_${backgroundId}.mp4`}
                    style={{}}
                    key={"gameplay_preview_" + backgroundId}
                    onlyPreview={true}
                    muted={true}
                    // duration={reel.end - reel.start}
                    setDuration={() => {}}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div>
                  <div className="text-center font-semibold text-xl">
                    PREVIEW MODE: Select Background
                  </div>
                  <div className="flex justify-center mt-4 flex-wrap max-w-[448px] m-auto max-h-[50vh] overflow-y-auto">
                    {_.map([4,1,6,3,5,2], (backgroundId) => (
                      <VideoPreview
                        videoSrc={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story-gameplay/gameplay_${backgroundId}.mp4`}
                        key={"gameplay_" + backgroundId}
                        onClick={() => setBackgroundId(backgroundId)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-6">
              <button className="btn bg-white text-black border-purple font-semibold py-4 px-1 w-full sm:w-44 text-center rounded-lg mb-4">
                Cancel
              </button>

              <button
                className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-44 sm:ml-auto disabled:opacity-75 mb-4"
                disabled={!loading ? false : true}
                onClick={handleSubmit}
              >
                {loading ? "Saving" : "Save"}
              </button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditStoryDialog;
