import React, { useContext, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid"; // Make sure to install heroicons package
import { Outlet, useNavigate } from "react-router-dom";
import "./style.scss";
import AuthContext from "contexts/authContext";
import { Paths } from "routes";
import _ from "lodash";
import PriceSelectionDialog from "dialogs/PriceSelection";

const UserHeader = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { changeAuth, auth } = useContext(AuthContext);

  return (
    <div className="user-header">
      <header className="py-4 px-2 md:px-8 bg-white border-b border-gray-200">
        <div className="max-w-screen-lg flex justify-between items-center mx-auto">
          <h1
            className="text-xl font-bold cursor-pointer"
            onClick={() => {
              navigate(Paths.Dashboard);
            }}
          >
            Captora
          </h1>
          <div className="relative">
            <button
              className="flex items-center text-md font-bold focus:outline-none capitalize"
              onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
            >
              {_.get(auth, "userData.FirstName", "")}{" "}
              <ChevronDownIcon className="ml-2 w-4 h-4" />
            </button>
            {isProfileMenuOpen && (
              <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-2xl z-20">
                {_.get(auth, "userData.Subscription[0].status", "") ===
                  "active" ||
                _.get(auth, "userData.Subscription[0].status", "") ===
                  "trialing" ? (
                  <PriceSelectionDialog>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm font-medium cursor-pointer border-b-[#E2E2E2] border-b"
                    >
                      Manage Subscription
                    </a>
                  </PriceSelectionDialog>
                ) : (
                  <PriceSelectionDialog>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm font-medium cursor-pointer border-b-[#E2E2E2] border-b"
                    >
                      Subscribe
                    </a>
                  </PriceSelectionDialog>
                )}
                {/* <a
                  href="#"
                  className="block px-4 py-2 text-sm font-medium cursor-pointer border-b-[#E2E2E2] border-b"
                >
                  Change Password
                </a> */}
                <a
                  href="#"
                  onClick={() => {
                    changeAuth({ isLoggedIn: false, userData: {} });
                    navigate(Paths.Login);
                  }}
                  className="block px-4 py-2 text-sm font-medium cursor-pointer"
                >
                  Log out
                </a>
              </div>
            )}
          </div>
        </div>
      </header>
      <div className="px-2 md:px-8">
        <div className="max-w-screen-lg mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
