import _ from "lodash";
import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const generateStoryContentWithAI = async (data) => {
  return await axiosInstance.post(endPoints.story.generateStoryWithAI, data);
};

export const generateStoryVideo = async (data) => {
  return await axiosInstance.post(endPoints.story.generateStoryVideo, data);
};

export const getStories = async () => {
  return await axiosInstance.get(endPoints.story.getStories);
};

export const deleteStory = async (jobId) => {
  return await axiosInstance.get(
    endPoints.story.deleteStory.replace(":job_id", jobId)
  );
};

export const updateStoryBackground = async (data) => {
  return await axiosInstance.post(endPoints.story.updateStoryBackground, data);
};
