import VoiceList from "component/VoiceList";
import InprogressStory from "dialogs/inprogressStory";
import Loader from "component/Loader";
import React, { useState } from "react";
import { generateStoryVideo } from "services/Story";

export default function CreateStoryStep4({
  setStep,
  storyContent,
  storyTopic,
  backgroundId,
  setJobId,
}) {
  const [downloading, setDownloading] = useState(false);
  const [voice, setVoice] = useState("Will");

  const handleDownloadStory = async () => {
    setDownloading(true);
    const response = await generateStoryVideo({
      storyContent: storyContent,
      backgroundId: backgroundId,
      voice: voice,
      titleContent: storyTopic
    });
    setJobId(response?.data);
    setDownloading(false);
    setStep(5);
  };

  return (
    <div className={`mt-4 rounded-md sm:h-auto overflow-auto`}>
      {/* {jobId && (
        <StoryPreview
          open={previewDialog}
          downloading={downloading}
          jobId={jobId}
          handleClose={() => setPreviewDialog(false)}
          downloadCompleted={() => {
            setIsDownloadCompleted(true)
            setDownloading(false)
          }}
          handleDownloadStoryVideo={handleDownloadStoryVideo}
        />
      )} */}
      {/* <InprogressStory
        open={storyProgress}
        setOpen={() => setStoryProgress(false)}
      /> */}
      {downloading ? (
        <div className="flex flex-col min-h-[500px]">
          <Loader
            title="Generating Story..."
            subtitle="Please be patient while we generate your story.."
          />
        </div>
      ) : (
        <>
          <h3 className="text-2xl font-semibold mb-4">Choose Voice</h3>
          <VoiceList setVoice={setVoice} voice={voice} />
          <div className="mt-8 flex flex-col-reverse sm:flex-row gap-3 sm:gap-0">
            <div
              className="font-semibold text-md content-center cursor-pointer hidden sm:block"
              onClick={() => setStep(3)}
              disabled
            >
              <span className="text-xl font-medium">{"<"}</span> Back
            </div>
            <div className="sm:ml-auto flex flex-col sm:flex-row gap-3 sm:gap-0">
              <button
                className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 disabled:opacity-75"
                onClick={() => handleDownloadStory()}
              >
                Generate
              </button>
              <button
                className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 blockssssss sm:hidden"
                onClick={() => setStep(3)}
              >
                Back to Step 3
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
