import { circleRedCrossIcon, dummyReel } from "assets";
import Loader from "component/Loader";
import EditStoryDialog from "dialogs/EditStory";
import DeleteStoryDialog from "dialogs/DeleteStory";
import React, { useEffect, useState } from "react";

let loading = {};
const checkVideoAvailability = async (url) => {
  if (loading[url]) return false;
  loading[url] = true;
  try {
    const res = await fetch(url, { method: "HEAD" });
    loading[url] = false;
    return res.ok;
  } catch (error) {
    loading[url] = false;
    return false;
  }
};

let timeoutRef = {};
function StoryPreview({
  inline = false,
  editActive = true,
  jobId,
  loadingTitle = "",
  setIsDownloadCompleted,
  deleteCallback = () => {},
  backgroundId
}) {
  const [hoverActive, setHoverActive] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [linkReady, setLinkReady] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [localBackgroundId, setLocalBackgroundId] = useState();

  useEffect(() => {
    if (backgroundId) {
      setLocalBackgroundId(backgroundId);
    }
  }, [backgroundId]);

  const checkIsReady = async (data) => {
    data?.backgroundId && setLocalBackgroundId(data.backgroundId);
    const dateSec = new Date().getTime();
    const isReady = await checkVideoAvailability(
      `https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story/${
        jobId ? jobId : ""
      }/${jobId ? jobId : ""}_output.png?${dateSec}`
    );

    if (isReady) {
      setIsDownloadCompleted(true)
      setLinkReady(true);
      setTimeout(() => setLoaded(true), 500);
      if (timeoutRef[jobId]) clearInterval(timeoutRef[jobId]);
    } else {
      setIsDownloadCompleted(false);
      setLinkReady(false);
      setLoaded(false);
    }
  };

  const videoUrl = `https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story/${
    jobId ? jobId : ""
  }/${jobId ? jobId : ""}_output.mp4`;

  const handleDownload = async (e) => {
    setDownloading(true);
    // Prevent the default anchor behavior
    e.preventDefault();
    e.stopPropagation();

    window.open(videoUrl, "_blank");
    setDownloading(false);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef[jobId]) clearInterval(timeoutRef[jobId]);
    };
  }, []);
  useEffect(() => {
    if (!linkReady) {
      if (timeoutRef[jobId]) clearInterval(timeoutRef[jobId]);
      timeoutRef[jobId] = setInterval(checkIsReady, 500);
    }
  }, [jobId, linkReady]);

  return (
    <div className="flex relative">
      {!loaded && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-30 content-center text-center flex flex-col bg-[#f4f4f4] bg-opacity-50">
          <Loader subtitle={loadingTitle} />
        </div>
      )}
      <div
        className="flex flex-col gap-2 relative w-full z-20 h-full"
        onMouseEnter={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      >
         {hoverActive && editActive && (
          <DeleteStoryDialog
            className="w-6 sm:w-8 md:w-8 absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer z-40"
            jobId={jobId}
            deleteCallback={() => {
              deleteCallback(jobId);
            }}
          >
            <img
              src={circleRedCrossIcon}
              alt="circleRedCrossIcon"
              className="z-40"
            />
          </DeleteStoryDialog>
        )}
         <img
          src={dummyReel}
          alt="dummyReel"
          className="rounded-lg grayscale-0"
        />
        <div className="rounded-lg absolute top-0 left-0 right-0 bottom-0 bg-slate-400 z-10 "></div>
        {linkReady && (
          <>
            <img
              src={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story/${
                jobId ? jobId : ""
              }/${jobId ? jobId : ""}_output.png?${Math.random()}`}
              alt="story"
              // width={200}
              // height={400}
              // className="rounded-lg grayscale-0 w-60 h-60"
              className="rounded-lg absolute top-0 left-0 right-0 bottom-0 z-20 w-full h-full object-cover"
            />
          </>
        )}

        {inline && hoverActive && editActive && (
          <>
            <div className="absolute top-0 left-0 right-0 bottom-0 content-center text-center flex flex-col  backdrop-blur-sm z-20 rounded-lg animated-backdrop-blur cursor-pointer"></div>
            <div className="flex flex-col gap-2 absolute left-0 right-0 bottom-0 p-2 z-20">
                <button
                  className="rounded-md w-full font-semibold text-sm bg-white h-9"
                  onClick={() => setEditOpen(true)}
                >
                  Edit
                </button>
                <button
                disabled={downloading}
                className="rounded-md w-full font-semibold text-sm bg-purple h-9 text-white disabled:opacity-75"
                onClick={handleDownload}
                >
                {downloading ? "Downloading..." : "Download"}
                </button>
            </div>
          </>
        )}
      </div>
      {editOpen && (
        <EditStoryDialog
          story={{
            backgroundId: localBackgroundId,
            jobId,
          }}
          open={editOpen}
          setOpen={setEditOpen}
          callback={checkIsReady}
        ></EditStoryDialog>
      )}
    </div>
  );
}

export default StoryPreview;