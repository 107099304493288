import React, { useState } from "react";
import Loader from "component/Loader";
import { generateStoryContentWithAI } from "services/Story";

function CreateStoryStep2({
  storyType,
  storyContent,
  storyTopic,
  voiceTone,
  storyLength,
  setStep,
  setStoryType,
  setStoryContent,
  setStoryTopic,
  setVoiceTone,
  setStoryLength,
}) {
  const [loading, setLoading] = useState(false);
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);

  const renderManualStoryContent = () => {
    return (
      <>
        <input
          value={storyTopic}
          id="prompt"
          type="text"
          placeholder="Input story topic here.."
          className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full mb-5 outline-none"
          onChange={(event) => setStoryTopic(event.target.value)}
        />
        <textarea
          onChange={(event) => setStoryContent(event.target.value)}
          id="story-content"
          name="story-content"
          rows="15"
          cols="41"
          placeholder="Input story text here..."
          className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
        >
          {storyContent}
        </textarea>
      </>
    );
  };

  const generateAIStoryContent = async () => {
    setIsGeneratingContent(true);
    const response = await generateStoryContentWithAI({
      storyTopic: storyTopic,
      storyLength: storyLength + "sec"
    });
    if (response?.data) {
      setStoryContent(response.data);
    }
    setIsGeneratingContent(false);
  };

  const renderAIStoryContent = () => {
    return (
      <div className="flex sm:gap-10 gap-0 sm:flex-row flex-col">
        <div className="flex-1">
          <div>
            {loading ? (
              <div className="flex flex-col min-h-[500px]">
                <Loader
                  title="Generating Story..."
                  subtitle="Please be patient while we generate content"
                />
              </div>
            ) : (
              <>
                <label
                  for="prompt"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Story Topic
                </label>
                <input
                  value={storyTopic}
                  id="prompt"
                  type="text"
                  placeholder="Ex How to get promoted"
                  className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full mb-5 outline-none"
                  onChange={(event) => setStoryTopic(event.target.value)}
                />
              </>
            )}
          </div>
          <div>
            <label
              for="large"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Tone of Voice
            </label>
            <select
              id="large"
              class="block w-full px-4 py-3 mb-5 text-base text-gray-900 border border-gray-300 rounded-lg focus-visible:outline-none"
              onChange={(event) => setVoiceTone(event.target.value)}
              value={voiceTone}
            >
              <option value="very_tense" selected>
                Very Tense
              </option>
              <option value="calm">Calm</option>
              <option value="informal">Informal</option>
              <option value="friendly">Friendly</option>
            </select>
          </div>
          <div>
            <label
              for="large"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Story Length
            </label>
            <select
              id="large"
              class="block w-full px-4 py-3 mb-5 text-base text-gray-900 border border-gray-300 rounded-lg focus-visible:outline-none"
              onChange={(event) => setStoryLength(event.target.value)}
              value={storyLength}
            >
              <option value="30" selected>
                30 Sec
              </option>
              <option value="45">45 Sec</option>
              <option value="60">60 Sec</option>
              <option value="90">90 Sec</option>
            </select>
          </div>
          <div className="w-full flex justify-center items-center pt-2">
            <button
              className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto disabled:opacity-75"
              onClick={() => generateAIStoryContent()}
              disabled={!storyTopic || isGeneratingContent}
            >
              Generate
            </button>
          </div>
        </div>
        {isGeneratingContent ? (
          <div className="flex-1 mt-7 border border-gray-300 flex justify-center items-center rounded-lg min-h-[309px]">
            <div className="flex justify-center items-center">
              <Loader
                title="Generating Story..."
                subtitle="Please be patient while we generate content"
              />
            </div>
          </div>
        ) : (
          <div className="flex-1 mt-7">
            <textarea
              id="story-content"
              name="story-content"
              rows="15"
              cols="41"
              placeholder="Click on Generate and story content will appear here..."
              onChange={(event) => setStoryContent(event.target.value)}
              className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
            >
              {storyContent}
            </textarea>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`mt-4 rounded-md sm:h-auto ${storyType === "manual" ? "h-[calc(100%_-_205px)]" : "h-[calc(100%_-_315px)]"} overflow-auto`}>
      <h3 className="text-2xl font-semibold mb-2">Story Content</h3>
      {storyType === "manual"
        ? renderManualStoryContent()
        : renderAIStoryContent()}
      <div className="mt-5 flex flex-col-reverse sm:flex-row gap-3 sm:gap-0">
        <div
          className="font-semibold text-md content-center cursor-pointer hidden sm:block"
          onClick={() => setStep(1)}
        >
          <span className="text-xl font-medium">{"<"}</span> Back
        </div>

        <div className="sm:ml-auto flex flex-col sm:flex-row gap-3 sm:gap-0">
          {storyType === "manual" && (
            <button
              className="btn bg-white text-black border-purple font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4"
              onClick={() => {
                setStoryType("AI");
                setStoryContent("");
                setStoryTopic("");
              }}
            >
              Generate with AI
            </button>
          )}
          <button
            className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 disabled:opacity-75"
            onClick={() => setStep(3)}
            disabled={!storyContent}
          >
            Add Backgound
          </button>
          <button
            className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 blockssssss sm:hidden"
            onClick={() => setStep(1)}
          >
            Back to Step 1
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateStoryStep2;
