import Loader from "component/Loader";
import StoryPreview from "component/StoryPreview";
import InprogressStory from "dialogs/inprogressStory";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function CreateStoryStep5({ jobId, backgroundId }) {
  const [loading, setLoading] = useState(false);
  const [inprogressReel, setInprogressReel] = useState(true);
  const [isDownloadCompleted, setIsDownloadCompleted] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="mb-12 mt-4 rounded-md">
      {loading && (
        <div className="flex flex-col min-h-[500px]">
          <Loader
            title="Generating Story..."
            subtitle="Please be patient while we generating your story.."
          />
        </div>
      )}
      {!loading && (
        <>
          <div className="rounded-lg overflow-hidden md:py-4  block">
            <div className="w-1/3 sm:w-1/4 md:w-1/5 p-2 inline-block">
              <StoryPreview
                inline
                jobId={jobId}
                loadingTitle={`Generating Story... Please wait..`}
                setIsDownloadCompleted={setIsDownloadCompleted}
                backgroundId={backgroundId}
                deleteCallback={() => {
                  navigate("/")
                }}
              />
            </div>
          </div>
          <div className="md:mt-8 flex flex-col md:flex-row gap-3 md:gap-0">
            <div
              className="font-semibold text-md content-center cursor-pointer hidden sm:block pl-2"
              onClick={() => navigate("/")}
            >
              <span className="text-xl font-medium">{"<"}</span> Back to
              Dashboard
            </div>
            <button
              className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 blockssssss sm:hidden"
              onClick={() => navigate("/")}
            >
              Back to Dashboard
            </button>
            <div className="sm:ml-auto flex flex-col sm:flex-row gap-3 sm:gap-0">
              <button
                className="btn bg-white text-black border-purple font-semibold py-4 px-12 rounded-lg w-full sm:w-auto md:ml-4"
                disabled={!isDownloadCompleted}
                onClick={() => {
                  window.open(
                    `https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story/${
                      jobId ? jobId : ""
                    }/${jobId ? jobId : ""}_output.mp4`,
                    "_blank"
                  );
                }}
              >
                Download
              </button>
            </div>
            <InprogressStory
              open={inprogressReel}
              setOpen={setInprogressReel}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default CreateStoryStep5;
