import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const loginService = async (data) => {
  return await axiosInstance.post(endPoints.auth.login, data, {
    hasLoader: true,
  });
};

export const signUpService = async (data) => {
  return await axiosInstance.post(endPoints.auth.register, data, {
    hasLoader: false,
  });
};

export const senOtpService = async (data) => {
  return await axiosInstance.post(endPoints.auth.sendOtp, data, {
    hasLoader: false,
  });
};

export const sendResetPasswordOtp = async (data) => {
  return await axiosInstance.post(endPoints.auth.sendResetPasswordOtp, data, {
    hasLoader: false,
  });
};

export const validateOtp = async (data) => {
  return await axiosInstance.post(endPoints.auth.validateOtp, data, {
    hasLoader: false,
  });
};

export const changePassword = async (data) => {
  return await axiosInstance.post(endPoints.auth.changePassword, data, {
    hasLoader: false,
  });
};

export const updatePassword = async (data) => {
  return await axiosInstance.post(endPoints.auth.updatePassword, data, {});
};

export const getUser = async () => {
  return await axiosInstance.get(endPoints.auth.getUser);
};

export const fetchSubscription = async () => {
  return await axiosInstance.get(endPoints.subscription.fetchSubscription);
};

export const createCheckoutSession = async (
  price = "monthly",
  skipTrial = false,
  isAfterSignUp = false
) => {
  return await axiosInstance.post(
    endPoints.subscription.createCheckoutSession,
    {
      price,
      skipTrial,
      from: window.location.protocol + "//" + window.location.host,
      isAfterSignUp,
    }
  );
};
