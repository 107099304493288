import { circleRedCrossIcon, dummyReel } from "assets";
import Loader from "component/Loader";
import DeleteReelDialog from "dialogs/DeleteReel";
import EditReelDialog from "dialogs/EditReel";
import React, { useEffect, useState } from "react";

let loading = {};
const checkVideoAvailability = async (url) => {
  if (loading[url]) return false;
  loading[url] = true;
  try {
    const res = await fetch(url, { method: "HEAD" });
    loading[url] = false;
    return res.ok;
  } catch (error) {
    loading[url] = false;
    return false;
  }
};

let timeoutRef = {};
function ReelPreview({
  inline = false,
  editActive = true,
  reel,
  loadingTitle = "",
  deleteCallback = () => {},
  newGamePlayCallback = () => {},
}) {
  const [hoverActive, setHoverActive] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [linkReady, setLinkReady] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const checkIsReady = async () => {
    const dateSec = new Date().getTime();
    const isReady = await checkVideoAvailability(
      `https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/reel/${
        reel ? reel.jobId : ""
      }/${reel ? reel.jobId : ""}_output.png?${dateSec}`
    );

    if (isReady) {
      setLinkReady(true);
      setTimeout(() => setLoaded(true), 500);
      if (timeoutRef[reel.jobId]) clearInterval(timeoutRef[reel.jobId]);
    } else {
      setLinkReady(false);
      setLoaded(false);
    }
  };

  const videoUrl = `https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/reel/${
    reel ? reel.jobId : ""
  }/${reel ? reel.jobId : ""}_output.mp4`;

  const handleDownload = async (e) => {
    setDownloading(true);
    // Prevent the default anchor behavior
    e.preventDefault();
    e.stopPropagation();

    window.open(videoUrl, "_blank");

    // Check if the URL is same-origin or a blob URL
    // if (
    //   videoUrl.startsWith("blob:") ||
    //   new URL(videoUrl, window.location.href).origin === window.location.origin
    // ) {
    //   // Create an anchor element and trigger the download
    //   const a = document.createElement("a");
    //   a.href = videoUrl;
    //   a.download = reel.name;
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // } else {
    //   // For cross-origin URLs, fetch the video and create a blob URL for download
    //   await fetch(videoUrl, {
    //     method: "GET",
    //     headers: new Headers({
    //       "Content-Type": "video/mp4",
    //     }),
    //     mode: "cors",
    //   })
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //       const blobUrl = window.URL.createObjectURL(blob);
    //       const a = document.createElement("a");
    //       a.href = blobUrl;
    //       a.download = reel.name;
    //       document.body.appendChild(a);
    //       a.click();
    //       window.URL.revokeObjectURL(blobUrl);
    //       document.body.removeChild(a);
    //     })
    //     .catch((error) => console.error("Download error:", error));
    // }
    setDownloading(false);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef[reel.jobId]) clearInterval(timeoutRef[reel.jobId]);
    };
  }, []);
  useEffect(() => {
    if (!linkReady) {
      if (timeoutRef[reel.jobId]) clearInterval(timeoutRef[reel.jobId]);
      timeoutRef[reel.jobId] = setInterval(checkIsReady, 500);
    }
  }, [reel, linkReady]);

  return (
    <div className="flex relative">
      {!loaded && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-30 content-center text-center flex flex-col bg-[#f4f4f4] bg-opacity-50">
          <Loader subtitle={loadingTitle} />
        </div>
      )}
      <div
        className="flex flex-col gap-2 relative w-full z-20"
        onMouseEnter={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
        onClick={() => setEditOpen(true)}
      >
        {hoverActive && editActive && (
          <DeleteReelDialog
            className="w-6 sm:w-8 md:w-8 absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer z-40"
            reel={reel}
            deleteCallback={() => {
              deleteCallback(reel.id);
            }}
          >
            <img
              src={circleRedCrossIcon}
              alt="circleRedCrossIcon"
              className="z-40"
            />
          </DeleteReelDialog>
        )}
        <img
          src={dummyReel}
          alt="dummyReel"
          className="rounded-lg grayscale-0"
        />
        <div className="rounded-lg absolute top-0 left-0 right-0 bottom-0 bg-slate-400 z-10 "></div>
        {linkReady && (
          <img
            src={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/reel/${
              reel ? reel.jobId : ""
            }/${reel ? reel.jobId : ""}_output.png`}
            alt="reel"
            className="rounded-lg absolute top-0 left-0 right-0 bottom-0 z-20"
          />
        )}

        {inline && hoverActive && editActive && (
          <>
            <div className="absolute top-0 left-0 right-0 bottom-0 content-center text-center flex flex-col  backdrop-blur-sm z-20 rounded-lg animated-backdrop-blur cursor-pointer"></div>
            <div className="flex flex-col gap-2 absolute left-0 right-0 bottom-0 p-2 z-20">
              {/* {!reel.isWithGamePlay && ( */}
              <button
                className="rounded-md w-full font-semibold text-sm bg-white h-9"
                onClick={() => setEditOpen(true)}
              >
                Edit
              </button>
              {/* )} */}
              <button
                disabled={downloading}
                className="rounded-md w-full font-semibold text-sm bg-purple h-9 text-white disabled:opacity-75"
                onClick={handleDownload}
              >
                {downloading ? "Downloading..." : "Download"}
              </button>
            </div>
          </>
        )}
        {!inline && editActive && (
          <>
            {hoverActive ? (
              <button
                className="rounded-md w-full font-semibold text-sm bg-[#D9D9D9B2] h-9"
                onClick={() => setEditOpen(true)}
              >
                Edit
              </button>
            ) : (
              <div className="h-9"></div>
            )}
          </>
        )}
      </div>
      {editOpen && (
        <EditReelDialog
          reel={reel}
          open={editOpen}
          setOpen={setEditOpen}
          callback={checkIsReady}
        ></EditReelDialog>
      )}
    </div>
  );
}

export default ReelPreview;
