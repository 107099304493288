import { forwardRef, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { crossIcon } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CutWithAIDialog = (props) => {
  const { children, className = "", cutCallback = () => {} } = props;

  const [open, setOpen] = useState(false);
  const [cutCount, setCutCount] = useState();

  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setCutCount("");
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  return (
    <>
      <span onClick={handleClickOpen} className={className}>
        {children}
      </span>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            maxWidth: "490px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="mb-12 mt-2 rounded-md max-w-4xl mx-auto">
              <div className="font-semibold text-2xl text-center mt-1">
                Cut With AI
              </div>
              <div className="text-md text-center mt-3 mx-auto max-w-[340px]">
                Select the number of reels you want to make from your video and
                we will use AI to cut them.
              </div>
              <div className="flex justify-center mt-6">
                <select
                  id="reels"
                  class="block p-4 w-full text-gray-700 rounded border border-gray-300 max-w-[280px] focus:outline-none"
                  placeholder="Select Number of Reels"
                  value={cutCount}
                  onChange={(e) => setCutCount(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Number of Reels
                  </option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>
              <div className="mx-auto flex gap-3 mt-6 max-w-[180px]">
                <button
                  className="btn bg-purple text-white font-medium py-4 text-center rounded-lg w-full sm:w-auto disabled:opacity-75 flex-1 px-1"
                  onClick={() => {
                    if (cutCount && +cutCount > 0) {
                      cutCallback(cutCount);
                      handleClose();
                    }
                  }}
                >
                  Cut
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CutWithAIDialog;
