import { greenCheckIcon } from "assets";
import React from "react";

function PriceCard({
  planName = "",
  lines = [],
  price = "",
  priceLine = "*per month, charged monthly",
  onClick = () => {},
  hasBorder = true,
}) {
  return (
    <div
      className={`flex-1 ${
        hasBorder ? "border" : "shadow-md"
      } p-4 border-[#E2E2E2] rounded-2xl flex flex-col md:min-h-[500px] bg-white`}
    >
      <div className="font-medium text-2xl text-center">{planName}</div>
      <div className="flex mt-4 flex-1">
        <div className="inline-flex flex-col gap-2 m-auto mt-1">
          {lines.map((l) => (
            <div className="flex text-lg gap-2">
              <img src={greenCheckIcon} alt="greenCheckIcon" key={l} />
              <span>{l}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="font-semibold text-4xl text-center mt-10 md:mt-0">
        {price}
      </div>
      <div className="text-center font-medium text-sm text-[#a8a8a8] mt-1">
        {priceLine}
      </div>
      <div className="flex py-6">
        <button
          className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto m-auto md:min-w-60"
          onClick={(e) => {
            if (onClick) onClick(e);
          }}
        >
          Subscribe
        </button>
      </div>
    </div>
  );
}

export default PriceCard;
