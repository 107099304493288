import React from "react";

function PurpleSpin() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-spin"
    >
      <path
        d="M51.7589 24.6066C52.5312 30.014 51.4359 35.523 48.6538 40.2237C45.8718 44.9244 41.5695 48.5354 36.4577 50.4604C31.3459 52.3853 25.7303 52.5089 20.5388 50.8107C15.3472 49.1126 10.8902 45.6943 7.904 41.1206C4.91783 36.5469 3.58117 31.0914 4.11481 25.6553C4.64845 20.2192 7.02048 15.1277 10.839 11.222C14.6576 7.31627 19.6943 4.82996 25.1169 4.1738C30.5396 3.51763 36.0239 4.73086 40.6639 7.61307"
        stroke="#7F43FF"
        strokeWidth="8"
      />
    </svg>
  );
}

export default PurpleSpin;
