import "./App.css";
// import {  } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "contexts/authContext";
import RootContextWrapper from "contexts/rootContext";
import AppInit from "utils/AppInit";
import Router from "component/Router";
import { ToastContainer } from "react-toastify";
import { MantineProvider } from "@mantine/core";

function App() {
  const THEME = createTheme({
    typography: {
      fontFamily: `'Open Sans', sans-serif`,
    },
  });
  return (
    <div>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <ThemeProvider theme={THEME}>
          <AuthProvider>
            <RootContextWrapper>
              <ToastContainer />
              <Router />
              {/* <VideoTrimmer url="http://localhost:3000/SampleVideo_1280x720_1mb.mp4" /> */}
              <AppInit />
            </RootContextWrapper>
          </AuthProvider>
        </ThemeProvider>
      </MantineProvider>
    </div>
  );
}

export default App;
