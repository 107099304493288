import { forwardRef } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { crossIcon } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InprogressReel = (props) => {
  const { open, setOpen } = props;

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            maxWidth: "490px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="mb-12 mt-2 rounded-md max-w-4xl mx-auto">
              <div className="font-semibold text-2xl text-center mt-1">
                We're currently building your Reel(s) in the background. You can
                only edit once they're complete!
              </div>
              <div className="text-md text-center mt-3 mx-auto max-w-[340px]">
                [This can take up to 1-2 minutes]
              </div>

              <div className="mx-auto flex gap-3 mt-6 max-w-[180px]">
                <button
                  className="btn bg-purple text-white font-medium py-4 text-center rounded-lg w-full sm:w-auto disabled:opacity-75 flex-1 px-1"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InprogressReel;
