import { circleWhiteCrossIcon, circleWhiteEditIcon, iIcon } from "assets";
import Loader from "component/Loader";
import VideoTrimmer from "component/VideoTrimmer";
import CutWithAIDialog from "dialogs/CutWithAI";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import TimeField from "react-simple-timefield";
import { Tooltip } from "react-tooltip";
import { getYtVideoService } from "services/Video";
import { getAICuts } from "services/reel";

const UntitledReel = ({
  rangeValue = [],
  setRangeValue = () => {},
  title,
  setTitle,
  active = false,
  setActive = () => {},
  removeReel = () => {},
  onSaveClick = () => {},
}) => {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  useEffect(() => {
    if (rangeValue.length === 2) {
      setStartTime(rangeValue[0]);
      setEndTime(rangeValue[1]);
    }
  }, [rangeValue]);

  const formatSeconds = (seconds) => {
    const date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  };

  return (
    <div
      className={`bg-white bg-opacity-10 p-3 mx-auto rounded-lg text-white w-full md:w-[264px] ${
        active ? "border-2 border-[#7f43ff]" : ""
      }`}
    >
      {!active && (
        <div className="flex gap-2">
          <div className="text-white text-sm flex-1 truncate">{title}</div>
          <img
            src={circleWhiteCrossIcon}
            alt="circleWhiteCrossIcon"
            className="cursor-pointer"
            onClick={removeReel}
          />
          <img
            src={circleWhiteEditIcon}
            alt="circleWhiteEditIcon"
            className="cursor-pointer"
            onClick={setActive}
          />
        </div>
      )}

      {active && (
        <>
          <input
            type="text"
            placeholder="Untitled Reel"
            className="text-sm py-3 px-2 mb-4 w-full rounded-md bg-black border-0 border-gray-100 outline-0 placeholder-gray-400 min-w-60"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="flex justify-center gap-3 mb-1">
            <TimeField
              value={formatSeconds(startTime)}
              className="text-sm py-3 px-2 mb-1 text-white rounded-md bg-black border-0 border-gray-100 outline-0 placeholder-gray-400 w-20"
              onChange={(e, value) => {
                const parts = value.split(":");
                const seconds =
                  parseInt(parts[0]) * 3600 +
                  parseInt(parts[1]) * 60 +
                  parseInt(parts[2]);
                setStartTime(seconds);
                setRangeValue([seconds, endTime]);
              }}
              style={{ width: "80px" }}
              showSeconds={true}
            />
            <TimeField
              value={formatSeconds(endTime)}
              className="text-sm py-3 px-2 mb-1 text-white rounded-md bg-black border-0 border-gray-100 outline-0 placeholder-gray-400 w-20"
              onChange={(e, value) => {
                const parts = value.split(":");
                const seconds =
                  parseInt(parts[0]) * 3600 +
                  parseInt(parts[1]) * 60 +
                  parseInt(parts[2]);
                setEndTime(seconds);
                setRangeValue([startTime, seconds]);
              }}
              style={{ width: "80px" }}
              showSeconds={true}
            />
          </div>
          <div className="text-sm text-center text-white">
            {formatSeconds(endTime - startTime)}
          </div>
          <div className="flex">
            <button
              className="text-white p-2 text-sm bg-white bg-opacity-15 text-center rounded-md disabled:opacity-50 mx-auto bg-purple min-w-24 mt-2"
              disabled={rangeValue.length >= 5}
              onClick={onSaveClick}
            >
              Save
            </button>
          </div>
        </>
      )}
    </div>
  );
};

function ReelCutterStep2({
  setStep,
  ytUrl,
  setJobIds = () => {},
  rangeValue,
  setRangeValue,
}) {
  const [ytVideo, setYtVideo] = useState(null);
  const [cutType, setCuteType] = useState("notSet");
  // const [rangeValue, setRangeValue] = useState([
  //   { title: "Untitled Reel", range: [0, 0] },
  // ]);
  const [activeReel, setActiveReel] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState([
    "Loading Video..",
    "Please be patient while we load your video, this can take upto 2 minutes..",
  ]);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    getYtVideo();
  }, [ytUrl]);

  const getYtVideo = async (wait = false, timeoutValue = 2000) => {
    const response = await getYtVideoService(ytUrl, wait);
    if (response.data === "wait") {
      setTimeout(() => {
        getYtVideo(true, timeoutValue);
      }, timeoutValue);
    } else {
      setYtVideo(response.data);
      setLoading(false);
    }
  };

  const AICutReels = async (count) => {
    setLoadingText([
      "Finding the best cuts for your Reel...",
      "Please be patient as we find the optimal cuts for your reels, allow upto 2 minutes..",
    ]);
    setLoading(true);
    const aiResponse = await getAICuts({
      cuts: count,
      url: ytUrl,
    });
    console.log(aiResponse);

    setLoadingText([
      "Cutting Reel.. ",
      "Please be patient while we cut your reels..",
    ]);
    setLoading(true);
    setRangeValue(
      aiResponse.map((r, index) => ({
        title: "AI - CUT - " + (index + 1),
        range: [Math.floor(r.start), Math.floor(r.end)],
      }))
    );

    // const response = await generateReels({
    //   reels: aiResponse.map((r, index) => ({
    //     title: "AI - CUT - " + (index + 1),
    //     start: Math.floor(r),
    //     end: Math.floor(r) + 60,
    //     url: ytUrl,
    //     gameId: 1,
    //   })),
    // });
    // setJobIds(_.get(response, "data", []));
    setStep(25);
    setLoading(false);
  };

  const cutReels = async () => {
    if (rangeValue.length > 0) setStep(25);
  };

  return (
    <div className="mb-12 mt-4 rounded-md">
      {loading && (
        <div className="flex flex-col min-h-[500px]">
          <Loader title={loadingText[0]} subtitle={loadingText[1]} />
        </div>
      )}
      {!loading && (
        <>
          <div className="rounded-lg overflow-hidden p-4 bg-black">
            <div className="flex gap-3 justify-center flex-col md:flex-row">
              <VideoTrimmer
                rangeValue={
                  activeReel > -1 ? rangeValue[activeReel].range : [0, 0]
                }
                setRangeValue={(value) => {
                  const currentReels = _.cloneDeep(rangeValue);
                  currentReels[activeReel].range = value;
                  setRangeValue(currentReels);
                }}
                streamUrl={ytVideo}
                onlyPreview={cutType === "notSet" || activeReel === -1}
                muted={cutType === "notSet" || activeReel === -1}
                duration={duration}
                setDuration={setDuration}
              />
              {cutType === "manual" && (
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-3">
                    {_.map(rangeValue, (reel, index) => (
                      <UntitledReel
                        rangeValue={reel.range}
                        setRangeValue={(value) => {
                          const currentReels = _.cloneDeep(rangeValue);
                          currentReels[index].range = value;
                          setRangeValue(currentReels);
                        }}
                        title={reel.title}
                        setTitle={(value) => {
                          const currentReels = _.cloneDeep(rangeValue);
                          currentReels[index].title = value;
                          setRangeValue(currentReels);
                        }}
                        active={index === activeReel}
                        setActive={() => setActiveReel(index)}
                        removeReel={() => {
                          const currentReels = _.cloneDeep(rangeValue);
                          currentReels.splice(index, 1);
                          setRangeValue(currentReels);
                          if (index === activeReel) {
                            setActiveReel(0);
                          } else if (index < activeReel) {
                            setActiveReel(activeReel - 1);
                          } else if (rangeValue.length === 0) {
                            setCuteType("notSet");
                          }
                        }}
                        key={reel.id}
                        onSaveClick={() => setActiveReel(-1)}
                      />
                    ))}
                  </div>
                  {
                    <button
                      className="text-white p-2 text-sm bg-white bg-opacity-15 text-center rounded-md disabled:opacity-50"
                      disabled={rangeValue.length >= 5}
                      onClick={() => {
                        const currentReels = _.cloneDeep(rangeValue);
                        currentReels.push({
                          title: "Untitled Reel",
                          range: [0, Math.min(180, duration)],
                        });
                        setRangeValue(currentReels);
                        setActiveReel(currentReels.length - 1);
                      }}
                    >
                      Add New Reel
                    </button>
                  }
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 flex flex-col-reverse sm:flex-row gap-3 sm:gap-0">
            <div
              className="font-semibold text-md content-center cursor-pointer hidden sm:block"
              onClick={() => setStep(1)}
            >
              <span className="text-xl font-medium">{"<"}</span> Back
            </div>
            <button
              className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 blockssssss sm:hidden"
              onClick={() => setStep(1)}
            >
              Back to Step 1
            </button>
            {cutType === "notSet" && (
              <>
                <div className="sm:ml-auto flex flex-col sm:flex-row gap-3 sm:gap-0">
                  <div className="cursor-pointer content-center hidden sm:block">
                    <Tooltip
                      id="info-tooltip"
                      place="top"
                      variant="light"
                      opacity={1}
                      className="shadow-lg"
                      children={
                        <div style={{ maxWidth: "330px" }}>
                          If you know which parts of the video you want to clip,
                          you can do so manually. If you're unsure about which
                          parts will go viral, use the AI feature.
                        </div>
                      }
                    />
                    <img
                      src={iIcon}
                      data-tooltip-id="info-tooltip"
                      alt="iIcon"
                    />
                  </div>
                  <button
                    className="btn bg-white text-black border-purple font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4"
                    onClick={() => setCuteType("manual")}
                  >
                    Cut Manually
                  </button>
                  <CutWithAIDialog cutCallback={AICutReels}>
                    <button
                      // disabled
                      className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 disabled:opacity-75"
                    >
                      Cut with AI
                    </button>
                  </CutWithAIDialog>
                </div>
              </>
            )}
            {cutType === "manual" && (
              <>
                <button
                  className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto ml-4 disabled:opacity-75 ml-auto"
                  onClick={cutReels}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ReelCutterStep2;
