const URI = Object.freeze(
  "https://xjp32mdvu8.execute-api.eu-west-1.amazonaws.com/dev/v1"
);
// const URI = Object.freeze("http://localhost:7000/v1");
export const APIURI = URI;

export const endPoints = Object.freeze({
  auth: {
    register: URI + "/auth/signup",
    sendOtp: URI + "/auth/sendOtp",
    login: URI + "/auth/login",
    getUser: URI + "/auth/getUser",
    sendResetPasswordOtp: URI + "/auth/send-reset-password-otp",
    validateOtp: URI + "/auth/validate-otp",
    changePassword: URI + "/auth/change-password",
    updatePassword: URI + "/auth/update-password",
  },
  ai: {},
  video: {
    getYt: URI + "/video/get-yt",
  },
  reel: {
    getReels: URI + "/reel",
    getUserReelsByJobIds: URI + "/reels/by-job-ids",
    generateReels: URI + "/reel/generate-gameplay",
    addGamePlay: URI + "/reel/update-gameplay",
    deleteReel: URI + "/reels/delete/:job_id",
    reqAICuts: URI + "/reel/req/ai-cut",
    getAICuts: URI + "/reel/ai-cut",
  },
  subscription: {
    fetchSubscription: URI + "/subscription",
    createCheckoutSession: URI + "/subscription/create-checkout-session",
  },
  story: {
    generateStoryWithAI: URI + "/story/generate-story-content",
    generateStoryVideo: URI + "/story/generate-story-video",
    updateStoryBackground: URI + "/story/update-story-background",
    getStories: URI + "/story",
    deleteStory: URI + "/story/delete/:job_id",
  },
  resource: {},
});
