import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { loginService } from "services/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import AuthContext from "contexts/authContext";

function Login() {
  const [loader, setLoader] = useState(false);
  const { changeAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-col">
      <div className="text-3xl font-semibold mt-7 mb-7">Log in</div>
      <Formik
        initialValues={{
          email: _.get(location, "state.email", ""),
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoader(true);
            const userData = await loginService({
              Email: values.email,
              Password: values.password,
            });

            if (userData) {
              changeAuth({ isLoggedIn: true, userData: userData.data });
              setStatus({ success: true });
              setSubmitting(false);
              navigate({
                pathname: "/",
                state: {
                  isRedirectFromRegister: false,
                  isRedirectFromLogin: true,
                },
              });
              try {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  UserId: userData.data.userId,
                });
              } catch (err) {
                console.log("error in data layer");
              }
            } else {
              setStatus({ success: false });
              setErrors({
                submit:
                  "Email or password is invalid, please check and try again",
              });
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            if (
              _.get(err, "response.data.code", "") ===
              "otp_verification_required"
            ) {
              //   navigate(Paths.otpVerifying);
            }
            setErrors({
              submit: _.get(err, "response.data.title", "Something went wrong"),
            });
            setSubmitting(false);
          } finally {
            setLoader(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} className="flex flex-col">
            <input
              name="email"
              placeholder="Email Address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
            />
            {Boolean(touched.email && errors.email) && (
              <div className="text-sm font-medium text-[#FF352A] mt-1">
                {touched.email && errors.email}
              </div>
            )}
            <input
              type="password"
              name="password"
              placeholder="Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full  mt-4 outline-none"
            />
            {Boolean(touched.password && errors.password) ? (
              <div className="text-sm font-medium text-[#FF352A] mt-1">
                {touched.password && errors.password}
              </div>
            ) : (
              <div className="mt-1 h-5"></div>
            )}
            {errors.submit ? (
              <div className="text-sm font-medium text-[#FF352A] mt-0 mb-3">
                {errors.submit}
              </div>
            ) : (
              <div className="mt-0 mb-3 h-5"></div>
            )}
            <button
              disabled={loader}
              className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto m-auto mt-0 mb-6 disabled:opacity-75"
            >
              {loader ? "Log in..." : "Log in"}
            </button>
          </form>
        )}
      </Formik>
      <div className="text-center">
        <span className="font-semibold cursor-pointer">Forgot password?</span>
      </div>
    </div>
  );
}

export default Login;
