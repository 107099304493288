import { circleDownArrowIcon, circleUpArrowIcon } from "assets";
import React, { useState } from "react";

function Accordion({ a, q, border = true }) {
  const [open, setOpen] = useState(false);
  return (
    <div className={border ? `border-b-[1px] py-2` : "py-2"}>
      <div
        className="border-solid border-[#E2E2E2] py-5 cursor-pointer flex justify-between items-center text-2xl font-medium text-black"
        onClick={() => setOpen(!open)}
      >
        {q}
        {open ? (
          <img src={circleUpArrowIcon} alt="circleUpArrowIcon" />
        ) : (
          <img src={circleDownArrowIcon} alt="circleDownArrowIcon" />
        )}
      </div>
      {open && <div className="text-lg mb-5 max-w-[787px] font-light">{a}</div>}
    </div>
  );
}

export default Accordion;
