import { forwardRef, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { crossIcon } from "assets";
import { addGamePlay } from "services/reel";
import VideoTrimmer from "component/VideoTrimmer";
import { VideoPreview } from "pages/ReelCutter/step2_5";
import _ from "lodash";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditReelDialog = (props) => {
  const { open, setOpen, reel, callback } = props;
  const [gameId, setGameId] = useState(reel.gameplayId || 1);

  const [loading, setLoading] = useState(false);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    const response = await addGamePlay({
      gameId,
      jobId: reel.jobId,
    });

    if (callback) callback(response.data);
    setLoading(false);
    handleClose();
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            maxWidth: "1080px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="flex">
              <div className="max-w-[30%]">
                <div className="flex-col rounded overflow-auto">
                  <VideoTrimmer
                    rangeValue={0 > -1 ? [0, reel.end - reel.start] : [0, 0]}
                    setRangeValue={(value) => {}}
                    streamUrl={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/reel/${reel.jobId}/scaled_${reel.jobId}_podcast.mp4`}
                    onlyPreview={true}
                    muted={false}
                    duration={reel.end - reel.start}
                    setDuration={() => {}}
                    loop={true}
                  />
                  <VideoTrimmer
                    rangeValue={[0, 0]}
                    setRangeValue={(value) => {}}
                    streamUrl={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/gameplay/gameplay_${gameId}_720.mp4`}
                    style={{}}
                    key={"gameplay_preview_" + gameId}
                    onlyPreview={true}
                    muted={true}
                    duration={reel.end - reel.start}
                    setDuration={() => {}}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div>
                  <div className="text-center font-semibold text-xl">
                    PREVIEW MODE: Select Game Play
                  </div>
                  <div className="flex justify-center mt-4 flex-wrap max-w-[448px] m-auto max-h-[50vh] overflow-y-auto">
                    {_.map([1, 2, 3, 4, 5, 6], (gameId) => (
                      <VideoPreview
                        videoSrc={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/gameplay/gameplay_${gameId}_720.mp4`}
                        key={"gameplay_" + gameId}
                        onClick={() => setGameId(gameId)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-6">
              <button className="btn bg-white text-black border-purple font-semibold py-4 px-1 w-full sm:w-44 text-center rounded-lg mb-4">
                Cancel
              </button>

              <button
                className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-44 sm:ml-auto disabled:opacity-75 mb-4"
                disabled={!loading ? false : true}
                onClick={handleSubmit}
              >
                {loading ? "Saving" : "Save"}
              </button>
            </div>
            {/* 
            <div className="mb-12 mt-4 rounded-md max-w-4xl mx-auto">
              <div className="text-center font-semibold text-2xl mb-7">
                Add Gameplay
              </div>
              <div className="flex gap-5 flex-col md:flex-row">
                <div className="w-[30%] hidden md:block">
                  <img
                    src={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/reel/${
                      reel ? reel.jobId : ""
                    }/${reel ? reel.jobId : ""}_output.png`}
                    alt="reel"
                    className="rounded-lg"
                  />
                </div>
                <div className="text-center flex-1 flex flex-col justify-center">
                  <h1 className="text-2xl font-semibold mb-4">
                    Copy Game Play Video Link
                  </h1>
                  <input
                    type="text"
                    value={reel.url}
                    // onChange={handleInputChange}
                    placeholder="Copy Game Play video link here..."
                    className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full mb-6 md:mb-20 outline-none max-w-[440px] mx-auto focus:outline-none "
                  />
                </div>
              </div>
              
            </div> */}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditReelDialog;
