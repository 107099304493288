import { Navigate, createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import UserHeader from "component/UserHeader";
import ReelCutter from "pages/ReelCutter";
import Landing from "pages/Landing";
import CreateStory from "pages/CreateStory"

export const Paths = {
  Dashboard: "/dashboard",
  UpdatePassword: "/change-password",
  ReelCutter: "/reel-cutter",
  CreateStory: "/create-story"
};

export const router = (auth = {}) => {
  let routes = [];
  const isLoggedIn = localStorage.getItem("token");
  if (isLoggedIn) {
    routes.push({
      path: "/",
      element: <UserHeader></UserHeader>,
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: Paths.ReelCutter,
          element: <ReelCutter />,
        },
        {
          path: Paths.CreateStory,
          element: <CreateStory />,
        },
        {
          path: "*",
          element: <Navigate to={"/"} />,
        },
      ],
    });
  } else {
    routes.push({
      path: "/",
      children: [
        {
          path: "/",
          element: <Landing />,
        },
        {
          path: "/",
          element: <Navigate to={Paths.Login} />,
        },
        {
          path: "*",
          element: <Navigate to={Paths.Login} />,
        },
      ],
    });
  }
  return createBrowserRouter(routes);
};
