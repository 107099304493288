import AuthContext from "contexts/authContext";
import React, { useContext } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "routes";

function Router() {
  const { auth } = useContext(AuthContext);
  return <RouterProvider router={router(auth)} />;
}

export default Router;
