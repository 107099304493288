import React from "react";
import { validateURL } from "utils/ytdl";

function ReelCutterStep1({ setStep, ytUrl, setYtUrl }) {
  const handleInputChange = (event) => {
    setYtUrl(event.target.value);
  };

  const handleSubmit = () => {
    setStep(2);
  };
  return (
    <div className="max-w-lg mt-12 md:mt-0 mx-auto text-center md:flex-1 content-center w-full mb-16">
      <div className="mb-12">
        <h1 className="text-4xl font-semibold mb-8">Copy Video Link</h1>
        <input
          type="text"
          value={ytUrl}
          onChange={handleInputChange}
          placeholder="Copy video link here..."
          className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full mb-8 outline-none"
        />

        <button
          disabled={validateURL(ytUrl) ? false : true}
          onClick={handleSubmit}
          className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto disabled:opacity-75"
        >
          Create Reel(s)
        </button>
      </div>
    </div>
  );
}

export default ReelCutterStep1;
