import React from "react";

function CreateStoryStep1({ setStep, setStoryType, handleReset }) {

    const handleSubmit = (storyType) => {
        setStoryType(storyType)
        setStep(2);
        handleReset();
    };
    return (
        <div className="max-w-lg mt-12 md:mt-0 mx-auto text-center md:flex-1 content-center w-full mb-16">
            <div className="mb-12">
                <h1 className="text-4xl font-semibold mb-2">Create a new Story</h1>
                <div
                    className={`text-sm flex-1 font-semibold py-1 px-2 text-center md:block mb-8`}
                >
                    You can can create a new story manually or generate it automatically with help of AI.
                </div>

                <div className="sm:ml-auto flex flex-col sm:flex-row gap-3 sm:gap-0">
                    <button
                        onClick={() => handleSubmit("manual")}
                        className="btn bg-white text-black border-purple font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 mr-4"
                    >
                        Create manually
                    </button>

                    <button
                        onClick={() => handleSubmit("AI")}
                        className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto disabled:opacity-75"
                    >
                        Generate with AI
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateStoryStep1;
