import PurpleSpin from "component/PurpleSpin";
import React from "react";

function Loader({ title, subtitle }) {
  return (
    <div className="m-auto">
      <div className="text-center flex flex-col items-center">
        <PurpleSpin />
        {title && <div className="font-semibold text-2xl mt-2">{title}</div>}
        {subtitle && (
          <div className="font-normal mt-0 px-2 py-2">{subtitle}</div>
        )}
      </div>
    </div>
  );
}

export default Loader;
