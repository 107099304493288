import _ from "lodash";
import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const generateReels = async (data) => {
  return await axiosInstance.post(endPoints.reel.generateReels, data);
};

export const addGamePlay = async (data) => {
  return await axiosInstance.post(endPoints.reel.addGamePlay, data);
};

export const getAICuts = async (data) => {
  await axiosInstance.post(endPoints.reel.reqAICuts, data);
  while (true) {
    const response = await axiosInstance.post(endPoints.reel.getAICuts, data);
    if (response.data && _.isArray(response.data)) {
      return response.data;
    }
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
};

export const getReels = async () => {
  return await axiosInstance.get(endPoints.reel.getReels);
};
export const getReelsByJobIds = async (jobIds) => {
  return await axiosInstance.post(endPoints.reel.getUserReelsByJobIds, {
    reels: jobIds,
  });
};

export const deleteReel = async (jobId) => {
  return await axiosInstance.get(
    endPoints.reel.deleteReel.replace(":job_id", jobId)
  );
};
