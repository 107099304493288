import React, { Fragment, useState } from "react";
import CreateStoryStep1 from "./step1";
import CreateStoryStep2 from "./step2";
import CreateStoryStep3 from "./step3";
import CreateStoryStep4 from "./step4";
import CreateStoryStep5 from "./step5";

function CreateStory() {
  const [step, setStep] = useState(1);
  const [storyType, setStoryType] = useState("");
  const [storyContent, setStoryContent] = useState("");
  const [storyTopic, setStoryTopic] = useState("");
  const [voiceTone, setVoiceTone] = useState("very_tense");
  const [storyLength, setStoryLength] = useState("30");
  const [backgroundId, setBackgroundId] = useState("");
  const [jobId, setJobId] = useState("");

  const createStorySteps = [
    "Choose Method",
    "Input Text/ Prompt",
    "Add Background",
    "Add Voice",
    "Preview"
  ];

  const handleReset = () => {
    setStoryContent("");
    setStoryTopic("");
    setVoiceTone("very_tense");
    setStoryLength("30");
    setBackgroundId("")
  };

  return (
    <div className="md:border sm:mt-10 mt-2 rounded-md w-full px-1 md:px-6 py-2 min-h-80vh flex ">
      <div className="container mx-auto p-4 min-h-full flex-1 flex flex-col">
        <div className="flex items-center justify-between mb-2">
          {createStorySteps.map((storyStep, index) => {
            return (
              <Fragment>
                <div
                  className={`text-sm flex-1 font-semibold py-1 px-2 text-center ${
                    step === index + 1 ? `` : `hidden opacity-50`
                  } md:block`}
                >
                  Step {index + 1}: {storyStep}
                </div>
                <div className="font-semibold opacity-50 hidden md:block">
                  {">"}
                </div>
              </Fragment>
            );
          })}
        </div>
        <div className="relative h-2 mb-2 rounded-full">
          <div className="bg-green h-2  rounded-full opacity-25 absolute inset-x-0 w-full"></div>
          <div
            className="bg-green h-2  rounded-full"
            style={{
              width: `${(100 * [0, 1, 2, 3, 4, 5][step === 25 ? 4 : step]) / 5}%`,
            }}
          ></div>
        </div>
        {step === 1 && (
          <CreateStoryStep1
            setStep={setStep}
            setStoryType={setStoryType}
            handleReset={handleReset}
          />
        )}

        {step === 2 && (
          <CreateStoryStep2
            storyType={storyType}
            storyContent={storyContent}
            storyTopic={storyTopic}
            voiceTone={voiceTone}
            storyLength={storyLength}
            setStep={setStep}
            setStoryType={setStoryType}
            setStoryContent={setStoryContent}
            setStoryTopic={setStoryTopic}
            setVoiceTone={setVoiceTone}
            setStoryLength={setStoryLength}
          />
        )}

        {step === 3 && (
          <CreateStoryStep3
            setStep={setStep}
            setBackgroundId={setBackgroundId}
            backgroundId={backgroundId}
          />
        )}

        {step === 4 && (
          <CreateStoryStep4
            setStep={setStep}
            backgroundId={backgroundId}
            storyContent={storyContent}
            storyTopic={storyTopic}
            setJobId={setJobId}
          />
        )}

        {step === 5 && <CreateStoryStep5 jobId={jobId} backgroundId={backgroundId} />}
      </div>
    </div>
  );
}

export default CreateStory;
