import React, { useState } from "react";
import ReelCutterStep1 from "./step1";
import ReelCutterStep2 from "./step2";
import ReelCutterStep3 from "./step3";
import ReelCutterStep25 from "./step2_5";

function ReelCutter() {
  const [step, setStep] = useState(1);
  const [ytUrl, setYtUrl] = useState("");
  const [jobIds, setJobIds] = useState([]);
  const [rangeValue, setRangeValue] = useState([
    { title: "Untitled Reel", range: [0, 0] },
  ]);

  return (
    <div className="md:border mt-10 rounded-md w-full px-1 md:px-6 py-2 min-h-80vh flex">
      <div className="container mx-auto p-4 min-h-full flex-1 flex flex-col">
        <div className="flex items-center justify-between mb-2">
          <div
            className={`text-sm flex-1 font-semibold py-1 px-2 text-center ${
              step === 1 ? `` : `hidden opacity-50`
            } md:block`}
          >
            Step 1: Copy Video Link
          </div>
          <div className="font-semibold opacity-50 hidden md:block">{">"}</div>
          <div
            className={`text-sm flex-1 font-semibold py-1 px-2 text-center ${
              step === 2 ? `` : `hidden opacity-50`
            } md:block`}
          >
            Step 2: Cut Video Into Reel(s)
          </div>
          <div
            className={`text-sm flex-1 font-semibold py-1 px-2 text-center ${
              step === 25 ? `` : `hidden opacity-50`
            } md:block`}
          >
            Step 3: Select Game Play
          </div>
          <div className="font-semibold opacity-50 hidden md:block">{">"}</div>
          <div
            className={`text-sm flex-1 font-semibold py-1 px-2 text-center ${
              step === 3 ? `` : `hidden opacity-50`
            } md:block`}
          >
            Step 4: Customize Reel(s)
          </div>
        </div>
        <div className="relative h-2 mb-6 rounded-full">
          <div className="bg-green h-2  rounded-full opacity-25 absolute inset-x-0 w-full"></div>
          <div
            className="bg-green h-2  rounded-full"
            style={{
              width: `${(100 * [0, 1, 2, 4, 3][step === 25 ? 4 : step]) / 4}%`,
            }}
          ></div>
        </div>
        {step === 1 && (
          <ReelCutterStep1
            setStep={setStep}
            ytUrl={ytUrl}
            setYtUrl={setYtUrl}
          />
        )}
        {step === 2 && (
          <ReelCutterStep2
            setStep={setStep}
            ytUrl={ytUrl}
            key={ytUrl}
            setJobIds={setJobIds}
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
          />
        )}
        {step === 25 && (
          <ReelCutterStep25
            setStep={setStep}
            ytUrl={ytUrl}
            key={ytUrl}
            setJobIds={setJobIds}
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
          />
        )}
        {step === 3 && <ReelCutterStep3 setStep={setStep} jobIds={jobIds} />}
      </div>
    </div>
  );
}

export default ReelCutter;
