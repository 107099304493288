import React, { useState, useRef, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import { PlayIcon, PauseIcon, AudioRemix } from "assets";

const AudioPlayer = ({
  id,
  audioSrc,
  audioName,
  isPlaying,
  onPlayPause,
  setVoice,
  voice,
}) => {
  const audioRef = useRef();

  const handlePlayPause = (event) => {
    event.stopPropagation();
    const audioElement = audioRef.current.audioEl.current;
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    onPlayPause(id);
  };

  useEffect(() => {
    if (!isPlaying) {
      audioRef.current.audioEl.current.pause();
    }
  }, [isPlaying]);

  const handleAudioEnd = () => {
    onPlayPause(null);
  };

  useEffect(() => {
    const audioElement = audioRef.current.audioEl.current;
    audioElement.addEventListener("ended", handleAudioEnd);

    return () => {
      audioElement.removeEventListener("ended", handleAudioEnd);
    };
  }, []);

  return (
    <div
      className={`flex gap-8 items-center border-2 rounded-xl p-2 border-${
        voice === id ? "purple" : "gray"
      } cursor-pointer sm:w-2/4 w-full`}
      onClick={() => setVoice(id)}
    >
      <ReactAudioPlayer
        src={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story/${audioSrc}.mp3`}
        ref={audioRef}
        controls={false}
      />
      <img
        src={isPlaying ? PauseIcon : PlayIcon}
        alt="crossIcon"
        className="cursor-pointer"
        onClick={handlePlayPause}
      />
      <span className="flex-1 font-semibold">{audioName}</span>
      {voice === id && <img
        src={AudioRemix}
        alt="crossIcon"
        className="ml-auto cursor-pointer"
      />}
    </div>
  );
};

const VoiceList = ({ setVoice, voice }) => {
  const [currentPlayingId, setCurrentPlayingId] = useState(null);

  const handlePlayPause = (id) => {
    setCurrentPlayingId((prevId) => (prevId === id ? null : id));
  };

  const audioFiles = [
    { audio: "voice_preview_will_audio", name: "Default", id: "Will" },
    { audio: "voice_preview_alice_audio", name: "Alice", id: "Alice" },
    { audio: "voice_preview_brian_audio", name: "Brian", id: "Brian" },
    { audio: "voice_preview_jessica_audio", name: "Jessica", id: "Jessica" },
  ];

  return (
    <div className="flex gap-4 flex-col">
      {audioFiles.map((audio) => (
        <AudioPlayer
          key={audio.id}
          id={audio.id}
          audioName={audio.name}
          audioSrc={audio.audio}
          isPlaying={currentPlayingId === audio.id}
          onPlayPause={handlePlayPause}
          setVoice={setVoice}
          voice={voice}
        />
      ))}
    </div>
  );
};

export default VoiceList;
