import Loader from "component/Loader";
import ReelPreview from "component/ReelPreview";
import InprogressReel from "dialogs/InprogressReel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { getReelsByJobIds } from "services/reel";

function ReelCutterStep3({ setStep, jobIds = [] }) {
  const [reels, setReels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inprogressReel, setInprogressReel] = useState(false);
  const navigate = useNavigate();

  const reelList = [];
  for (let i = 0; i < reels.length; i++) {
    reelList.push(
      <>
        <div className="w-1/3 sm:w-1/4 md:w-1/5 p-2 inline-block">
          <ReelPreview
            inline
            reel={reels[i]}
            key={reels[i].id}
            loadingTitle={` Cutting Reel... Please wait..`}
            deleteCallback={(id) => {
              setReels(reels.filter((r) => r.id !== id));
            }}
            newGamePlayCallback={(newJobId) => {
              setReels([newJobId, ...reels]);
            }}
          />
        </div>
      </>
    );
  }

  const getRecentReels = async () => {
    const result = await getReelsByJobIds(jobIds);
    setReels(result.data);
    setLoading(false);
    setInprogressReel(true);
  };
  useEffect(() => {
    getRecentReels();
  }, []);

  return (
    <div className="mb-12 mt-4 rounded-md">
      {loading && (
        <div className="flex flex-col min-h-[500px]">
          <Loader
            title="Cutting Reel.. "
            subtitle="Please be patient while we cut your reels.."
          />
        </div>
      )}
      {!loading && (
        <>
          <div className="rounded-lg overflow-hidden md:py-4  block">
            {reelList}
          </div>
          <div className="ml-auto cursor-pointer content-center">
            <Tooltip
              id="info-tooltip"
              place="top"
              variant="light"
              opacity={1}
              className="shadow-lg z-50"
              children={
                <div style={{ maxWidth: "330px" }}>
                  Please hover over the reel you want to add gameplay to, and
                  click "edit"
                </div>
              }
            />
          </div>
          <div className="md:mt-8 flex flex-col md:flex-row gap-3 md:gap-0">
            <div
              className="font-semibold text-md content-center cursor-pointer hidden sm:block pl-2"
              onClick={() => navigate("/")}
            >
              <span className="text-xl font-medium">{"<"}</span> Back to
              Dashboard
            </div>
            <button
              className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 blockssssss sm:hidden"
              onClick={() => navigate("/")}
            >
              Back to Dashboard
            </button>
            <div className="sm:ml-auto flex flex-col sm:flex-row gap-3 sm:gap-0">
              <button
                className="btn bg-white text-black border-purple font-semibold py-4 px-12 rounded-lg w-full sm:w-auto md:ml-4"
                data-tooltip-id="info-tooltip"
              >
                Add Gameplay
              </button>
            </div>
            <InprogressReel open={inprogressReel} setOpen={setInprogressReel} />
            {/* <button className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto md:ml-4 disabled:opacity-75">
              Download All
            </button> */}
          </div>
        </>
      )}
    </div>
  );
}

export default ReelCutterStep3;
