import { forwardRef, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { crossIcon } from "assets";
import Login from "component/Login";
import Signup from "component/Signup";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginDialog = (props) => {
  const { children, className = "" } = props;

  const [open, setOpen] = useState(false);
  const [activeMode, setActiveMode] = useState("login");

  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  return (
    <>
      <span onClick={handleClickOpen} className={className}>
        {children}
      </span>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            maxWidth: "528px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="mb-6 mt-4 rounded-md max-w-4xl mx-auto md:px-5">
              <div className="flex">
                <div
                  className={`w-20 text-center py-2 text-sm cursor-pointer ${
                    activeMode === "login" ? " font-semibold" : ""
                  }`}
                  onClick={() => setActiveMode("login")}
                >
                  Log in
                </div>
                <div
                  className={`w-20 text-center py-2 text-sm cursor-pointer ${
                    activeMode === "signup" ? " font-semibold" : ""
                  }`}
                  onClick={() => setActiveMode("signup")}
                >
                  Sign up
                </div>
              </div>
              <div className="w-full bg-[#E2E2E2] rounded-sm">
                <div
                  className={`h-1 w-20 bg-[#7F43FF] rounded-sm ${
                    activeMode === "login" ? "" : "ml-20"
                  }`}
                ></div>
              </div>
              {activeMode === "login" ? <Login /> : <Signup />}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoginDialog;
