import Loader from "component/Loader";
import VideoTrimmer from "component/VideoTrimmer";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { getYtVideoService } from "services/Video";
import { generateReels } from "services/reel";

export const VideoPreview = ({ videoSrc, onClick = () => {} }) => {
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    if (videoRef.current && window.innerWidth > 768) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current && window.innerWidth > 768) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video to start
    }
  };

  return (
    <div
      className="w-32 h-32 rounded overflow-hidden cursor-pointer m-2"
      onClick={onClick}
    >
      <div
        className="relative w-full h-32 pb-full overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {window.innerWidth > 768 ? (
          <video
            ref={videoRef}
            src={videoSrc}
            className="absolute top-1/2 left-1/2 w-full h-auto transform -translate-x-1/2 -translate-y-1/2"
            muted
            loop
            preload="auto"
          />
        ) : (
          <video
            ref={videoRef}
            src={videoSrc}
            className="absolute top-1/2 left-1/2 w-full h-auto transform -translate-x-1/2 -translate-y-1/2"
            muted={true}
            loop={true}
            playsInline
            autoPlay={true}
            controls={false}
            preload="auto"
          />
        )}
      </div>
    </div>
  );
};
function ReelCutterStep25({
  setStep,
  ytUrl,
  setJobIds = () => {},
  rangeValue,
}) {
  const [ytVideo, setYtVideo] = useState(null);
  const [gameId, setGameId] = useState(1);
  // const [rangeValue, setRangeValue] = useState([
  //   { title: "Untitled Reel", range: [0, 0] },
  // ]);

  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState([
    "Loading Video..",
    "Please be patient while we load your video..",
  ]);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    getYtVideo();
  }, [ytUrl]);

  const getYtVideo = async (wait = false, timeoutValue = 2000) => {
    const response = await getYtVideoService(ytUrl, wait);
    if (response.data === "wait") {
      setTimeout(() => {
        getYtVideo(true, timeoutValue * 1.05);
      }, timeoutValue);
    } else {
      setYtVideo(response.data);
      setLoading(false);
    }
  };

  const cutReels = async () => {
    setLoadingText([
      "Cutting Reel.. ",
      "Please be patient while we cut your reels..",
    ]);
    setLoading(true);
    const response = await generateReels({
      reels: rangeValue.map((r) => ({
        title: r.title,
        start: r.range[0],
        end: r.range[1],
        url: ytUrl,
        gameId: gameId,
      })),
    });
    setJobIds(_.get(response, "data", []));
    setStep(3);
    setLoading(false);
  };

  return (
    <div className="mb-12 mt-4 rounded-md">
      {loading && (
        <div className="flex flex-col min-h-[500px]">
          <Loader title={loadingText[0]} subtitle={loadingText[1]} />
        </div>
      )}
      {!loading && (
        <>
          <div className="flex">
            <div className="max-w-[30%]">
              <div className="flex-col rounded overflow-auto">
                <div className="aspect-ratio-9-7">
                  <VideoTrimmer
                    rangeValue={0 > -1 ? rangeValue[0].range : [0, 0]}
                    setRangeValue={(value) => {}}
                    streamUrl={ytVideo}
                    onlyPreview={true}
                    muted={false}
                    duration={duration}
                    setDuration={setDuration}
                  />
                </div>
                <VideoTrimmer
                  rangeValue={[0, 0]}
                  setRangeValue={(value) => {}}
                  streamUrl={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/gameplay/gameplay_${gameId}_720.mp4`}
                  style={{}}
                  key={"gameplay_preview_" + gameId}
                  onlyPreview={true}
                  muted={true}
                  duration={duration}
                  setDuration={setDuration}
                />
              </div>
            </div>
            <div className="flex-1">
              <div>
                <div className="text-center font-semibold text-xl">
                  Select Game Play
                </div>
                <div className="flex justify-center mt-4 flex-wrap max-w-[448px] m-auto max-h-[50vh] overflow-y-auto">
                  {_.map([1, 2, 3, 4, 5, 6], (gameId) => (
                    <VideoPreview
                      videoSrc={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/gameplay/gameplay_${gameId}_720.mp4`}
                      key={"gameplay_" + gameId}
                      onClick={() => setGameId(gameId)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col-reverse sm:flex-row gap-3 sm:gap-0">
            <div
              className="font-semibold text-md content-center cursor-pointer hidden sm:block"
              onClick={() => setStep(2)}
            >
              <span className="text-xl font-medium">{"<"}</span> Back
            </div>
            <button
              className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto sm:ml-4 blockssssss sm:hidden"
              onClick={() => setStep(2)}
            >
              Back to Step 2
            </button>
            <>
              <button
                className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto ml-4 disabled:opacity-75 ml-auto"
                onClick={cutReels}
              >
                Next
              </button>
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default ReelCutterStep25;
