import { Box, RangeSlider } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { formatDuration } from "./utils/formatDuration";
import Loader from "component/Loader";

const VideoTrimmer = ({
  streamUrl,
  rangeValue,
  setRangeValue,
  onlyPreview,
  muted = false,
  duration,
  setDuration,
  style = {
    maxHeight: "70vh",
  },
}) => {
  const videoRef = useRef();

  const [start, end] = rangeValue;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    videoRef.current && (videoRef.current.currentTime = start);
    if (duration < start) {
      setRangeValue([duration, end]);
    } else if (start > end) {
      setRangeValue([start, start]);
    } else if (end - start > 180) {
      setRangeValue([start, start + 180]);
    }
  }, [start]);

  useEffect(() => {
    videoRef.current && (videoRef.current.currentTime = end);
    if (duration < end) {
      setRangeValue([start, duration]);
    }
    if (end - start > 180) {
      setRangeValue([end - 180, end]);
    }
  }, [end]);

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.onloadedmetadata = () => {
      setDuration(videoRef.current?.duration || 0);
      setRangeValue([0, Math.min(180, videoRef.current?.duration || 0)]);
    };
  }, [videoRef, setDuration]);

  return (
    <Box pos="relative">
      <div className="relative">
        <video
          src={streamUrl}
          autoPlay={true}
          muted={muted ? true : false}
          controls={onlyPreview ? false : true}
          onCanPlayThrough={() => setLoading(false)}
          onWaiting={() => setLoading(true)}
          startTime={start}
          endTime={end}
          loop={true}
          width="100%"
          style={style}
          ref={videoRef}
          preload="auto"
          playsInline
        />
        {loading && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loader />
          </div>
        )}
      </div>
      <div
        style={{ ...(onlyPreview ? { display: "none" } : {}) }}
        className="p-2"
      >
        <RangeSlider
          h={16}
          w="100%"
          step={1}
          max={duration}
          minRange={1}
          showLabelOnHover={false}
          radius="xs"
          color="indigo"
          value={rangeValue}
          onChange={setRangeValue}
          label={formatDuration}
          styles={{
            thumb: { height: 16 },
            track: {
              "::before": { backgroundColor: "unset" },
            },
            bar: { backgroundColor: "unset" },
          }}
        />
      </div>
    </Box>
  );
};

export default VideoTrimmer;
