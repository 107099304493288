import { forwardRef, useContext } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { crossIcon, FullScreenReelType, SplitScreenReelType } from "assets";
import { Paths } from "routes";
import { useNavigate } from "react-router-dom";
import AuthContext from "contexts/authContext";
import _ from "lodash";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReelType = (props) => {
  const { open, setOpen } = props;
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            maxWidth: "656px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="mb-12 mt-2 rounded-md max-w-4xl mx-auto">
              <div className="font-semibold text-2xl text-center mt-1">
                Choose Reel Type
              </div>
              <div className="flex sm:flex-nowrap flex-wrap justify-center gap-8 mt-4 min-h-[280px] sm:h-[280px] h-full">
                <div
                  className="flex flex-col items-center justify-center sm:min-w-[256px] min-w-[200px] h-full border-2 border-gray rounded-md hover:border-purple-600 cursor-pointer"
                  onClick={() => {
                    if (
                      _.get(auth, "userData.Subscription[0].status", "") ===
                        "active" ||
                      _.get(auth, "userData.Subscription[0].status", "") ===
                        "trialing"
                    )
                      navigate(Paths.ReelCutter);
                  }}
                >
                  <img
                    src={SplitScreenReelType}
                    alt="SplitScreenReelType"
                    className="mt-3 mx-auto"
                  />
                  <div className="text-md font-semibold text-center mt-3 mx-auto">
                    Split Screen
                  </div>
                </div>
                <div
                  className="flex flex-col items-center justify-center sm:min-w-[256px] min-w-[200px] h-full border-2 border-gray rounded-md hover:border-purple-600 cursor-pointer"
                  onClick={() => {
                    if (
                      _.get(auth, "userData.Subscription[0].status", "") ===
                        "active" ||
                      _.get(auth, "userData.Subscription[0].status", "") ===
                        "trialing"
                    )
                      navigate(Paths.CreateStory);
                  }}
                >
                  <img
                    src={FullScreenReelType}
                    alt="SplitScreenReelType"
                    className="mt-3 mx-auto"
                  />
                  <div className="text-md font-semibold text-center mt-3 mx-auto">
                    Full Screen
                  </div>
                </div>
              </div>
            </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReelType;
