import React, { Fragment, useContext, useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline"; // Make sure to install heroicons package
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import ReelPreview from "component/ReelPreview";
import StoryPreview from "component/StoryPreview";
import LoginDialog from "dialogs/Login";
import { getReels } from "services/reel";
import Loader from "component/Loader";
import _ from "lodash";
import AuthContext from "contexts/authContext";
import PriceSelectionDialog from "dialogs/PriceSelection";
import ReelTypeDialog from "dialogs/ReelType";
import { getStories } from "services/Story";

const Dashboard = () => {
  const [activeMode, setActiveMode] = useState("reel");
  const [isReelTypeOpen, setIsReelTypeOpen] = useState(false);
  const [reels, setReels] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const getRecentReels = async () => {
    setLoading(true);
    const result =
      activeMode === "reel" ? await getReels() : await getStories();
    setReels(result.data);
    setLoading(false);
  };

  useEffect(() => {
    getRecentReels();
  }, [activeMode]);

  // const createReelCard = (
  //   <Card
  //     title="Cut a Reel from Video"
  //     description="Create multiple videos instantly!"
  //     icon={videoIcon}
  //     onClick={() => {
  //       if (
  //         _.get(auth, "userData.Subscription[0].status", "") === "active" ||
  //         _.get(auth, "userData.Subscription[0].status", "") === "trialing"
  //       )
  //         navigate(Paths.ReelCutter);
  //     }}
  //   />
  // );

  const createReelBtn = (
    <button
      className="btn bg-purple text-white font-medium py-4 px-6 rounded-lg w-full sm:w-auto m-auto mt-0 mb-2 disabled:opacity-75 ml-auto flex gap-1 items-end"
      onClick={() => {
        if (
          _.get(auth, "userData.Subscription[0].status", "") === "active" ||
          _.get(auth, "userData.Subscription[0].status", "") === "trialing"
        )
          navigate(Paths.ReelCutter);
      }}
    >
      <PlusIcon stroke="white" className="h-5 w-5 text-white" />
      Create New Reel
    </button>
  );

  const createStoryBtn = (
    <button
      className="btn bg-purple text-white font-medium py-4 px-6 rounded-lg w-full sm:w-auto m-auto mt-0 mb-2 ml-4 disabled:opacity-75 flex gap-1 items-end"
      onClick={() => {
        if (
          _.get(auth, "userData.Subscription[0].status", "") === "active" ||
          _.get(auth, "userData.Subscription[0].status", "") === "trialing"
        )
          navigate(Paths.CreateStory);
      }}
    >
      <PlusIcon stroke="white" className="h-5 w-5 text-white" />
      Create New Story
    </button>
  );

  const createNewReelBtn = (
    <button
      className="btn bg-purple text-white font-medium py-4 px-6 rounded-lg w-full sm:w-auto m-auto mt-0 mb-2 ml-4 disabled:opacity-75 flex gap-1 items-end"
      onClick={() => {
        if (
          _.get(auth, "userData.Subscription[0].status", "") === "active" ||
          _.get(auth, "userData.Subscription[0].status", "") === "trialing"
        )
          setIsReelTypeOpen(true);
      }}
    >
      <PlusIcon stroke="white" className="h-5 w-5 text-white" />
      Create Reel
    </button>
  );

  return (
    <div className="md:border mt-10 rounded-md w-full mb-10">
      <div className="max-w-872 p-2 md:p-4 md:mt-8 sm:mt-4 mx-auto min-h-80vh flex flex-col">
        <LoginDialog />
        <ReelTypeDialog open={isReelTypeOpen} setOpen={setIsReelTypeOpen} />
        <div className="flex items-end self-end">
          {_.get(auth, "userData.Subscription[0].status", "") === "active" ||
          _.get(auth, "userData.Subscription[0].status", "") === "trialing" ? (
            <Fragment>
              {/* {createReelBtn}
              {createStoryBtn} */}
              {createNewReelBtn}
            </Fragment>
          ) : (
            <PriceSelectionDialog>{createReelBtn}</PriceSelectionDialog>
          )}
        </div>
        <h1 className="text-3xl text-center font-semibold mb-4 mt-4">
          Your Dashboard
        </h1>

        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          {_.get(auth, "userData.Subscription[0].status", "") === "active" ||
          _.get(auth, "userData.Subscription[0].status", "") === "trialing" ? (
            createReelCard
          ) : (
            <PriceSelectionDialog>{createReelCard}</PriceSelectionDialog>
          )}

          <Card
            title="Create a new Story"
            description="Create viral stories at the click of a button!"
            icon={storyIcon}
          />
        </div> */}

        <div className="border-t border-gray-200 pt-4 flex flex-col flex-1">
          <TabBar activeMode={activeMode} setActiveMode={setActiveMode} />
          {loading && (
            <div className="flex flex-col min-h-[500px]">
              <Loader />
            </div>
          )}
          {!loading && reels.length === 0 && (
            <div className="text-center min-h-full text-gray-500 mt-1 mb-8 flex-1 content-center">
              This is empty for now..
            </div>
          )}
          {!loading && reels.length > 0 && (
            <div className="min-h-full text-gray-500 mt-1 mb-8 flex-1">
              {_.map(reels, (reel) => (
                <div
                  className="w-1/3 sm:w-1/4 md:w-1/5 inline-block p-2"
                  key={reel?.id || reel?.jobId}
                >
                  {activeMode === "reel" ? (
                    <ReelPreview
                      inline
                      reel={reel}
                      key={reel.jobId}
                      deleteCallback={(id) => {
                        setReels(reels.filter((r) => r.id !== id));
                      }}
                      newGamePlayCallback={(newJobId) =>
                        setReels([newJobId, ...reels])
                      }
                    />
                  ) : (
                    <StoryPreview
                      inline
                      jobId={reel.jobId}
                      setIsDownloadCompleted={() => {}}
                      deleteCallback={((jobId) => {
                        setReels(reels.filter((r) => r.jobId !== jobId))
                      })}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// const Card = ({ title, description, icon, onClick }) => {
//   return (
//     <div
//       className="border rounded-2xl p-1 sm:p-2 flex items-center shadow-sm border-gray-200 cursor-pointer"
//       onClick={(e) => {
//         if (onClick) onClick(e);
//       }}
//     >
//       <div className="p-2 rounded-2xl sm:mr-2">
//         <img src={icon} alt="icon" />
//       </div>
//       <div>
//         <h2 className="font-semibold">{title}</h2>
//         <p className="text-sm text-gray-600">{description}</p>
//       </div>
//       <button className="ml-auto bg-gray-100 p-2 rounded-full mr-2">
//         <PlusIcon color="black" className="h-5 w-5 text-black" />
//       </button>
//     </div>
//   );
// };

const TabBar = ({ activeMode, setActiveMode }) => {
  return (
    <div className="flex space-x-3">
      <TabButton
        active={activeMode === "reel"}
        onClick={() => {
          setActiveMode("reel");
        }}
        title="Reels"
      />
      <TabButton
        active={activeMode === "story"}
        onClick={() => {
          setActiveMode("story");
        }}
        title="Stories"
      />
    </div>
  );
};

const TabButton = ({ title, active, onClick }) => {
  return (
    <button
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      className={`px-4 py-2 tracking-wide rounded-3xl text-sm ${
        active ? "bg-black text-white" : "text-black bg-gray-100"
      }`}
    >
      {title}
    </button>
  );
};

export default Dashboard;
