import { forwardRef, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { crossIcon } from "assets";
import _ from "lodash";
import { deleteStory } from "services/Story";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteStoryDialog = (props) => {
  const { children, className = "", jobId, deleteCallback = () => {} } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleDeleteStory = async () => {
    setLoading(true);
    await deleteStory(jobId);
    deleteCallback(jobId);
    handleClose();
    setLoading(false);
  };

  return (
    <>
      <span onClick={handleClickOpen} className={className}>
        {children}
      </span>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            maxWidth: "504px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={crossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer"
              onClick={handleClose}
            />
            <div className="mb-12 mt-4 rounded-md max-w-4xl mx-auto">
              <div className="w-24 m-auto">
                <img
                  src={`https://dev-clipio-bucket.s3.eu-west-1.amazonaws.com/story/${
                    jobId ? jobId : ""
                  }/${jobId ? jobId : ""}_output.png?${Math.random()}`}
                  alt="story"
                  className="rounded-lg "
                />
              </div>
              <div className="font-semibold text-2xl text-center mt-4">
                Delete this Story?
              </div>
              <div className="text-md text-center mt-3 max-w-sm mx-auto">
                Are you sure you want to permanently delete story? This action is
                irreversible!
              </div>
              <div className=" max-w-sm mx-auto flex gap-3 mt-6">
                <button
                  disabled={loading}
                  className="btn bg-white text-black border-purple font-medium py-4 text-center rounded-lg w-full sm:w-auto flex-1 px-1"
                  data-tooltip-id="info-tooltip"
                  onClick={handleClose}
                >
                  No, Keep it
                </button>
                <button
                  disabled={loading}
                  className="btn bg-purple text-white font-medium py-4 text-center rounded-lg w-full sm:w-auto disabled:opacity-75 flex-1 px-1"
                  onClick={handleDeleteStory}
                >
                  Yes, Delete
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteStoryDialog;
