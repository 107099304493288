import React, { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { senOtpService, signUpService } from "services/Auth";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import AuthContext from "contexts/authContext";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Paths } from "routes";

function Signup() {
  const [loader, setLoader] = useState(false);
  const { changeAuth } = useContext(AuthContext);
  const [signUpData, setSignUpData] = useState({});
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const params = new URLSearchParams(window.location.search);

  return (
    <div className="flex flex-col">
      <div className="text-3xl font-semibold mt-7 mb-7">
        {_.isEmpty(signUpData) ? "Sign up" : "We’ve just sent you a code"}
      </div>
      {_.isEmpty(signUpData) ? (
        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            email: params.get("email") || "",
            password: "",
            confirmpassword: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string().max(255).required("First name is required"),
            lastname: Yup.string().max(255).required("Last name is required"),
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string()
              .min(6)
              .max(255)
              .required("Password is required"),
            confirmpassword: Yup.string()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Confirm Password is required"),
            hCaptcha: Yup.string().required("Captcha is required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, setFieldValue }
          ) => {
            try {
              setLoader(true);
              if (senOtpService) {
                // const token = await executeRecaptcha("SignUp");
                const userData = await senOtpService({
                  FirstName: values.firstname,
                  LastName: values.lastname,
                  Password: values.password,
                  Email: values.email,
                  CaptchaCode: values.hCaptcha,
                });

                if (userData) {
                  setSignUpData({
                    FirstName: values.firstname,
                    LastName: values.lastname,
                    Password: values.password,
                    Email: values.email,
                  });
                }
                captchaRef.current?.resetCaptcha();
                setFieldValue("hCaptcha", "");
              }
            } catch (err) {
              console.error(err);
              captchaRef.current?.resetCaptcha();
              setStatus({ success: false });
              setErrors({
                submit: _.get(
                  err,
                  "response.data.title",
                  "Something went wrong"
                ),
              });

              setSubmitting(false);
            } finally {
              setLoader(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="flex flex-col">
              <input
                name="firstname"
                placeholder="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstname}
                className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
              />
              {Boolean(touched.firstname && errors.firstname) && (
                <div className="text-sm font-medium text-[#FF352A] mt-1">
                  {touched.firstname && errors.firstname}
                </div>
              )}
              <input
                placeholder="Last Name"
                name="lastname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastname}
                className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none mt-4"
              />
              {Boolean(touched.lastname && errors.lastname) && (
                <div className="text-sm font-medium text-[#FF352A] mt-1">
                  {touched.lastname && errors.lastname}
                </div>
              )}
              <input
                placeholder="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none mt-4"
              />
              {Boolean(touched.email && errors.email) && (
                <div className="text-sm font-medium text-[#FF352A] mt-1">
                  {touched.email && errors.email}
                </div>
              )}
              <input
                type="password"
                placeholder="Password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none mt-4"
              />
              {Boolean(touched.password && errors.password) && (
                <div className="text-sm font-medium text-[#FF352A] mt-1">
                  {touched.password && errors.password}
                </div>
              )}
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmpassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmpassword}
                className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none mt-4"
              />
              {Boolean(touched.confirmpassword && errors.confirmpassword) && (
                <div className="text-sm font-medium text-[#FF352A] mt-1">
                  {touched.confirmpassword && errors.confirmpassword}
                </div>
              )}
              <div className="flex flex-col mt-6">
                <HCaptcha
                  ref={captchaRef}
                  sitekey="e0babe09-2202-4ea1-9882-55edfc867418"
                  onVerify={(token) => {
                    setFieldValue("hCaptcha", token);
                  }}
                  onExpire={() => {
                    setFieldValue("hCaptcha", "");
                  }}
                  className="flex"
                />
                {Boolean(errors.hCaptcha) && (
                  <div className="text-sm font-medium text-[#FF352A] mt-1 w-[300px] m-auto">
                    {errors.hCaptcha}
                  </div>
                )}
              </div>
              {errors.submit ? (
                <div className="text-sm font-medium text-[#FF352A] mt-0 mb-3">
                  {errors.submit}
                </div>
              ) : (
                <div className="mt-0 mb-3 h-5"></div>
              )}
              <button
                disabled={loader}
                className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto m-auto mt-0 mb-6 disabled:opacity-75"
              >
                {loader ? "Signing up..." : "Sign up"}
              </button>
            </form>
          )}
        </Formik>
      ) : (
        <>
          <input
            placeholder={`Code sent to ${signUpData.Email}`}
            onChange={(e) => {
              if (
                ((!isNaN(e.target.value) &&
                  Number.isInteger(parseFloat(e.target.value))) ||
                  _.isEmpty(e.target.value)) &&
                e.target.value.length <= 6
              ) {
                setOtp(e.target.value);
              }
            }}
            value={otp}
            className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
          />
          {Boolean(otpError) && (
            <div className="text-sm font-medium text-[#FF352A] mt-1">
              {otpError}
            </div>
          )}
          <button
            disabled={otp.length !== 6 || loader}
            onClick={async () => {
              try {
                setLoader(true);
                if (signUpService) {
                  setLoader(true);
                  const userData = await signUpService({
                    ...signUpData,
                    Otp: otp,
                  });
                  if (userData) {
                    try {
                      window.fpr("referral", {
                        email: _.get(userData, "data.Email", ""),
                      });
                    } catch (err) {
                      console.error("error in firstpromoter referral", err);
                    }
                    setLoader(false);
                    changeAuth({
                      isLoggedIn: true,
                      userData: userData.data,
                    });
                    navigate({
                      pathname: Paths.SignUpDone,
                      state: { isRedirectFromRegister: true },
                    });
                  }
                  setLoader(false);
                }
              } catch (err) {
                console.error(err);
                setLoader(false);
                setOtpError(
                  _.get(err, "response.data.title", "Something went wrong")
                );
              } finally {
                setLoader(false);
              }
            }}
            className="btn bg-purple text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto m-auto mt-5 mb-6 disabled:opacity-75"
          >
            {loader ? "Verifying..." : "Verify"}
          </button>
        </>
      )}
      <div className="text-center font-light">
        <span>
          By signing up, you are agreeing to our{" "}
          <span className="font-semibold color-purple cursor-pointer">
            Terms & Policies
          </span>
        </span>
      </div>
    </div>
  );
}

export default Signup;
